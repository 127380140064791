import angleRight from '../../assets/icons/right-arrow.svg';
import apple from '../../assets/icons/apple-pay.svg';
import arrowDown from '../../assets/icons/arrow-down.svg';
import arrowUp from '../../assets/icons/arrow-up.svg';
import backArrow from '../../assets/icons/backArrow.svg';
import bin from '../../assets/icons/bin.svg';
import calendar from '../../assets/icons/calendar.svg';
import card from '../../assets/icons/card.svg';
import cart from '../../assets/icons/cart.svg';
import category from '../../assets/icons/category.svg';
import check from '../../assets/icons/check-filled.svg';
import checkCircle from '../../assets/icons/checkCircle.svg';
import clock from '../../assets/icons/clock.svg';
import coloredCheck from '../../assets/icons/check.svg';
import crossCircle from '../../assets/icons/crossCircle.svg';
import currency from '../../assets/icons/currency.svg';
import error from '../../assets/icons/icon-error.svg';
import google from '../../assets/icons/google-pay.svg';
import grayPencil from '../../assets/icons/grayPencil.svg';
import help from '../../assets/icons/help.svg';
import home from '../../assets/icons/home.svg';
import language from '../../assets/icons/language.svg';
import leftAngle from '../../assets/icons/leftAngle.svg';
import mail from '../../assets/icons/mail.svg';
import messenger from '../../assets/icons/messenger.svg';
import minus from '../../assets/icons/minus.svg';
import minusCircle from '../../assets/icons/minusCircle.svg';
import notification from '../../assets/icons/information-circle.svg';
import paypal from '../../assets/icons/paypal.svg';
import pencil from '../../assets/icons/pencil.svg';
import plus from '../../assets/icons/plus.svg';
import plusCircle from '../../assets/icons/plusCircle.svg';
import priceTag from '../../assets/icons/pricetags.svg';
import processing from '../../assets/icons/processing.svg';
import promoCode from '../../assets/icons/promoCode.svg';
import purpleLocation from '../../assets/icons/location.svg';
import rightAngleWhite from '../../assets/icons/right-angle-white.svg';
import search from '../../assets/icons/search.svg';
import seat from '../../assets/icons/seat.svg';
import settings from '../../assets/icons/settings.svg';
import shop from '../../assets/icons/shop.svg';
import syncCircle from '../../assets/icons/sync-circle.svg';
import ticket from '../../assets/icons/ticket.svg';
import tickittoLogo from '../../assets/icons/logo.svg';
import user from '../../assets/icons/user.svg';
import visa from '../../assets/icons/visa-logo.svg';
import wallet from '../../assets/icons/wallet.svg';
import whatsApp from '../../assets/icons/whatsApp.svg';
import phone from '../../assets/icons/call.svg';
import chatBubble from '../../assets/icons/chatbubble-ellipses.svg';
import xSymbol from '../../assets/icons/x-symbol.svg';
import time from '../../assets/icons/time.svg';
import band from '../../assets/icons/band.svg';
import venue from '../../assets/icons/venue.svg';
import filterLocation from '../../assets/icons/filterLocation.svg';
import available from '../../assets/icons/available.svg';
import fastFilling from '../../assets/icons/fastFilling.svg';
import food from '../../assets/icons/food.svg';
import mobile from '../../assets/icons/mobile.svg';
import info from '../../assets/icons/info.svg';
import stage from '../../assets/icons/stage.svg';
import zoomin from '../../assets/icons/zoomin.svg';
import zoomout from '../../assets/icons/zoomout.svg';
import accessibility from '../../assets/icons/accessibility.svg';

export interface Icon {
  src: string;
  alt: string;
}

export type IconID =
  | 'apple'
  | 'arrowDown'
  | 'arrowUp'
  | 'backArrow'
  | 'bin'
  | 'calendar'
  | 'card'
  | 'cart'
  | 'category'
  | 'chatBubble'
  | 'check'
  | 'checkCircle'
  | 'clock'
  | 'coloredCheck'
  | 'crossCircle'
  | 'currency'
  | 'error'
  | 'google'
  | 'grayPencil'
  | 'help'
  | 'home'
  | 'language'
  | 'leftAngle'
  | 'logo'
  | 'mail'
  | 'messenger'
  | 'minus'
  | 'minusCircle'
  | 'notification'
  | 'paypal'
  | 'pencil'
  | 'phone'
  | 'plus'
  | 'plusCircle'
  | 'priceTag'
  | 'processing'
  | 'promoCode'
  | 'rightAngle'
  | 'rightAngleWhite'
  | 'search'
  | 'seat'
  | 'settings'
  | 'shop'
  | 'syncCircle'
  | 'ticket'
  | 'tickittoLogo'
  | 'user'
  | 'visa'
  | 'wallet'
  | 'whatsApp'
  | 'location'
  | 'xSymbol'
  | 'time'
  | 'band'
  | 'venue'
  | 'filterLocation'
  | 'available'
  | 'fastFilling'
  | 'food'
  | 'mobile'
  | 'info'
  | 'stage'
  | 'zoomin'
  | 'zoomout'
  | 'accessibility';

export type IconsDataType = Record<IconID, Icon>;

const icons: IconsDataType = {
  phone: {
    src: phone,
    alt: 'phone',
  },
  chatBubble: {
    src: chatBubble,
    alt: 'chatBubble',
  },
  shop: {
    src: shop,
    alt: 'shop',
  },
  backArrow: {
    src: backArrow,
    alt: 'back arrow',
  },
  plusCircle: {
    src: plusCircle,
    alt: 'plus circle',
  },
  minusCircle: {
    src: minusCircle,
    alt: 'minus circle',
  },
  syncCircle: {
    src: syncCircle,
    alt: 'loading icon',
  },
  location: {
    src: purpleLocation,
    alt: 'location icon',
  },
  priceTag: {
    src: priceTag,
    alt: 'price tag',
  },
  ticket: {
    src: ticket,
    alt: 'ticket icon',
  },
  rightAngle: {
    src: angleRight,
    alt: 'right icon',
  },
  search: {
    src: search,
    alt: 'search icon',
  },
  calendar: {
    src: calendar,
    alt: 'calendar icon',
  },
  rightAngleWhite: {
    src: rightAngleWhite,
    alt: 'right arrow',
  },
  user: {
    src: user,
    alt: 'user icon',
  },
  wallet: {
    src: wallet,
    alt: 'wallet icon',
  },
  check: {
    src: check,
    alt: 'check icon',
  },
  logo: {
    src: tickittoLogo,
    alt: 'website logo',
  },
  tickittoLogo: {
    src: tickittoLogo,
    alt: 'provider logo',
  },
  cart: {
    src: cart,
    alt: 'cart icon',
  },
  help: {
    src: help,
    alt: 'help icon',
  },
  seat: {
    src: seat,
    alt: 'seat icon',
  },
  clock: {
    src: clock,
    alt: 'clock icon',
  },
  apple: {
    src: apple,
    alt: 'apple icon',
  },
  google: {
    src: google,
    alt: 'google icon',
  },
  paypal: {
    src: paypal,
    alt: 'paypal icon',
  },
  visa: {
    src: visa,
    alt: 'visa icon',
  },
  notification: {
    src: notification,
    alt: 'notification icon',
  },
  home: {
    src: home,
    alt: 'home icon',
  },
  processing: {
    src: processing,
    alt: 'processing icon',
  },
  card: {
    src: card,
    alt: 'card icon',
  },
  mail: {
    src: mail,
    alt: 'mail icon',
  },
  messenger: {
    src: messenger,
    alt: 'messenger icon',
  },
  whatsApp: {
    src: whatsApp,
    alt: 'whatsApp icon',
  },
  checkCircle: {
    src: checkCircle,
    alt: 'checkCircle icon',
  },
  plus: {
    src: plus,
    alt: 'plus icon',
  },
  minus: {
    src: minus,
    alt: 'plus icon',
  },
  arrowDown: {
    src: arrowDown,
    alt: 'arrow down icon',
  },
  arrowUp: {
    src: arrowUp,
    alt: 'arrow up icon',
  },
  language: {
    src: language,
    alt: 'language icon',
  },
  currency: {
    src: currency,
    alt: 'currency icon',
  },
  crossCircle: {
    src: crossCircle,
    alt: 'cross icon',
  },
  category: {
    src: category,
    alt: 'category icon',
  },
  settings: {
    src: settings,
    alt: 'settings icon',
  },
  leftAngle: {
    src: leftAngle,
    alt: 'left angle icon',
  },
  pencil: {
    src: pencil,
    alt: 'pencil icon',
  },
  grayPencil: {
    src: grayPencil,
    alt: 'pencil icon',
  },
  bin: {
    src: bin,
    alt: 'bin icon',
  },
  coloredCheck: {
    src: coloredCheck,
    alt: 'check icon',
  },
  promoCode: {
    src: promoCode,
    alt: 'promo code icon',
  },
  xSymbol: {
    src: xSymbol,
    alt: 'x symbol icon',
  },
  time: {
    src: time,
    alt: 'time symbol icon',
  },
  band: {
    src: band,
    alt: 'band symbol icon',
  },
  venue: {
    src: venue,
    alt: 'venue symbol icon',
  },
  filterLocation: {
    src: filterLocation,
    alt: 'filterLocation symbol icon',
  },
  available: {
    src: available,
    alt: 'available symbol icon',
  },
  fastFilling: {
    src: fastFilling,
    alt: 'fastFilling symbol icon',
  },
  food: {
    src: food,
    alt: 'food symbol icon',
  },
  mobile: {
    src: mobile,
    alt: 'mobile symbol icon',
  },
  info: {
    src: info,
    alt: 'info symbol icon',
  },
  stage: {
    src: stage,
    alt: 'stage symbol icon',
  },
  zoomin: {
    src: zoomin,
    alt: 'zoomin symbol icon',
  },
  zoomout: {
    src: zoomout,
    alt: 'zoomout symbol icon',
  },
  accessibility: {
    src: accessibility,
    alt: 'accessibility symbol icon',
  },
  error: {
    src: error,
    alt: 'error icon',
  },
};

export default icons;
