export type ResponseType = 'json';
export interface FetchArgs<TResponseType extends ResponseType = ResponseType>
  extends RequestInit {
  url: string;
  method: string;
  responseType: TResponseType;
  params?: Record<string, string>;
}

export class FetchError extends Error {
  public readonly status: number;

  public readonly message: string;

  public readonly text: string;

  public constructor(status: number, message: string, text: string) {
    super(`${status} ${message}`);
    this.status = status;
    this.message = message;
    this.text = text;
  }
}

export function fetch(args: FetchArgs<'json'>): Promise<unknown>;
export function fetch(args: FetchArgs): Promise<unknown>;
export default async function fetch(args: FetchArgs): Promise<unknown> {
  let { url } = args;
  if (args.params) {
    const urlObj = new URL(args.url);
    Object.entries(args.params).forEach(([key, value]) => {
      urlObj.searchParams.set(key, value);
    });
    url = `${urlObj}`;
  }

  const res = await window.fetch(url, args);
  if (!res.ok) {
    throw new FetchError(res.status, res.statusText, await res.text());
  }
  switch (args.responseType) {
    case 'json':
      return res.json();
    default:
      throw new FetchError(
        res.status,
        `Unknown response type: ${args.responseType}`,
        await res.text()
      );
  }
}
