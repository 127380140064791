import { useContext, useState } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import LocationIQ from '../definitions/location-iq';
import { EventsContext, Location } from '../contextProviders/EventsProvider';
import { useRateLimit } from '../utils/customHooks';
import fetch, { FetchArgs, FetchError } from './fetch';

const apiUrl = process.env.REACT_APP_LOCATIONIQ_API_URL;
const apiKey = process.env.REACT_APP_LOCATIONIQ_API_KEY;

export enum LocationTypes {
  PLACES = 'place',
  CITIES = 'place:city',
  TOWNS = 'place:town',
  VILLAGES = 'place:village',
}
export interface SearchQueryOptions {
  readonly types?: ReadonlyArray<LocationTypes>;
  /** Delay in ms before sending a query; used to reduce requests/avoid rate limits while typing. */
  readonly delay?: number;
  /** Time before a cache entry will become invalid. */
  readonly staleTime?: number;
}

/** Fetches an array result, returning an empty array on 404 instead of throwing an error. */
async function fetchArray(
  args: FetchArgs
): Promise<ReadonlyArray<LocationIQ.SearchResult>> {
  try {
    return (await fetch(args)) as ReadonlyArray<LocationIQ.SearchResult>;
  } catch (err) {
    if (err instanceof FetchError && err.status === 404) {
      return [];
    }
    throw err;
  }
}

/** Returns a React Query object for a location search ('autocomplete'), returning the raw LocationIQ results. */
export function useSearchQuery(
  input: string,
  { types = [], delay = 1000, staleTime = Infinity }: SearchQueryOptions = {}
): QueryObserverResult<ReadonlyArray<LocationIQ.SearchResult>> {
  // Rate limit reduces the number of requests, otherwise we make a request every time the user types a character
  const [canRequest, onRequest] = useRateLimit({
    interval: delay,
    callLimit: 1,
  });
  // TODO PR on react-query to add this to context arg
  const [isRetry, setIsRetry] = useState(false);

  const trimmed = input.trim();
  const query = useQuery(
    ['search', trimmed, types],
    async () => {
      if (!apiUrl || !apiKey) {
        console.error('LocationIQ not configured');
        return [];
      }
      const url = new URL(apiUrl);
      url.pathname = 'v1/autocomplete.php';
      if (!isRetry) {
        onRequest();
      }
      if (!trimmed) {
        return [];
      }
      try {
        const result = await fetchArray({
          method: 'GET',
          url: `${url}`,
          responseType: 'json',
          params: {
            key: apiKey,
            q: trimmed,
            tag: types.join(','),
            'accept-language': 'en',
          },
        });
        if (isRetry) {
          setIsRetry(false);
        }
        return result;
      } catch (err) {
        if (!isRetry) {
          setIsRetry(true);
        }
        throw err;
      }
    },
    { enabled: canRequest, staleTime }
  );

  return query;
}

/** Returns an array of Location for the given input using LocationIQ autocomplete. */
export function useLocationSearch(input: string): ReadonlyArray<Location> {
  const search = useSearchQuery(input, {
    types: [LocationTypes.CITIES, LocationTypes.TOWNS],
  });

  const [{ locations }] = useContext(EventsContext);
  const locationResults: Record<string, Location> = {};
  if (search.data && !search.isError) {
    const matchlessKeys: string[] = [];
    search.data.forEach(function searchResultToLocation(p) {
      if (!p.address) {
        return;
      }

      if (p.address.name === 'Reykjavik') p.address.name = 'Reykjavík';
      // this api is pretty legit tbf
      const { name, city, state, country, country_code } = p.address;

      if (!city && !name) {
        return;
      }

      if (!name || !country || !country_code) {
        return;
      }

      const key = city
        ? `${city} :: ${state} :: ${country_code}`.toLowerCase()
        : `${name} :: ${state} :: ${country_code}`.toLowerCase();
      if (key in locationResults) {
        return;
      }

      const match = city
        ? locations.find(
            (l) =>
              l.city.toLowerCase() === city.toLowerCase() &&
              l.country_code.toLowerCase() === country_code.toLowerCase()
          )
        : locations.find(
            (l) =>
              l.city.toLowerCase() === name.toLowerCase() &&
              l.country_code.toLowerCase() === country_code.toLowerCase()
          );

      if (match) {
        locationResults[key] = match;
      } else {
        matchlessKeys.push(key);
        if (p.lat == null || p.lon == null) {
          return;
        }

        const detail = city
          ? state && state.trim() !== city?.trim()
            ? `, ${state}`
            : ''
          : state && state.trim() !== name?.trim()
          ? `, ${state}`
          : '';

        locationResults[key] = {
          city: city ? city + detail : name + detail,
          country,
          country_code,
          latitude: parseFloat(p.lat),
          longitude: parseFloat(p.lon),
          images: [],
          event_count: 0,
        };
      }
    });

    if (
      (process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_ENVIRONMENT === 'development') &&
      !search.isStale &&
      matchlessKeys.length > 0
    ) {
      console.warn(
        `${matchlessKeys.length} key${
          matchlessKeys.length === 1 ? '' : 's'
        } had no exact matches:`,
        matchlessKeys
      );
    }
  }
  return Object.values(locationResults);
}

const client = {
  useLocationSearch,
};

export default client;
