import { useQuery } from 'react-query';
import { useContext } from 'react';
import tktApi from '../instances/tickitto';
import {
  SeatingPlanSeatsData,
  SeatingPlanBlocksData,
} from '../../definitions/tickitto';
import { SettingsContext } from '../../contextProviders/SettingsProvider';

function useSeats(
  eventId: string,
  performanceId: string,
  blockId: string | null | undefined,
  quantity: number | undefined | null,
  onError: (err: unknown) => void = () => {}
): SeatingPlanSeatsData | null {
  const { locale } = useContext(SettingsContext);
  const query = useQuery<SeatingPlanSeatsData>(
    [
      '/seating/seats',
      'GET',
      {
        event_id: eventId,
        performance_id: performanceId,
        block_id: blockId,
        quantity,
      },
    ],
    async () => {
      const { data } = await tktApi.get('seating/seats', {
        params: {
          event_id: eventId,
          performance_id: performanceId,
          block_id: blockId,
          quantity,
        },
        headers: {
          'accept-language': locale || 'en',
        },
      });

      return data;
    },
    {
      onError,
      staleTime: 1000 * 60 * 1,
      enabled: !!(eventId && performanceId && blockId),
    }
  );
  if (blockId == null) {
    return null;
  }
  if (query.isFetching) {
    return null;
  }
  return query.data || null;
}

function useSeatPlan(
  eventId: string,
  performanceId: string,
  onError: (err: unknown) => void = () => {}
): SeatingPlanBlocksData | null {
  const { locale } = useContext(SettingsContext);
  const query = useQuery<SeatingPlanBlocksData>(
    [
      '/seating/seating',
      'GET',
      {
        event_id: eventId,
        performance_id: performanceId,
      },
    ],
    async () => {
      const { data } = await tktApi.get('seating/', {
        params: {
          event_id: eventId,
          performance_id: performanceId,
        },
        headers: {
          'accept-language': locale || 'en',
        },
      });
      return data;
    },
    {
      onError,
      staleTime: 1000 * 60 * 1,
      refetchOnWindowFocus: false,
    }
  );
  if (query.isFetching) {
    return null;
  }
  return query.data || null;
}

const client = {
  useSeats,
  useSeatPlan,
};

export default client;
