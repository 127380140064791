/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import StackdriverErrorReporter from 'stackdriver-errors-js';

export interface ErrorReportingContextType {
  throwError: (error: any, extraData?: any) => void;
  setUser?: (user: string) => void;
}

export const ErrorReportingContext =
  React.createContext<ErrorReportingContextType>({
    throwError: (error) => {
      throw error;
    },
  });

interface IErrorHandler {
  report: (error: any) => void;
  setUser?: (user: string) => void;
}

// class based since there is no way to do componentDidCatch in functional comps
export class ErrorReportingProvider extends React.Component {
  protected errorHandler: IErrorHandler | null;

  constructor(props: React.PropsWithChildren<{}>) {
    super(props);

    this.throwError = this.throwError.bind(this);
    this.errorHandler = null;
  }

  public componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      const errorHandler = new StackdriverErrorReporter();
      this.errorHandler = errorHandler;
      errorHandler.start({
        key: process.env.REACT_APP_ERROR_REPORTING_API_KEY,
        projectId: 'tickitto-api-v1',
      });
    } else {
      this.errorHandler = { report: console.error, setUser: () => {} };
    }
  }

  public componentDidCatch(error: any, errorInfo: any) {
    this.throwError(error, errorInfo);
  }

  public setUser(userString: string) {
    if (this?.errorHandler) {
      this.errorHandler.setUser!(userString);
    }
  }

  public throwError(error: any, extraData?: any) {
    console.error('Error Caught!', error, extraData);

    // if (extraData != null) {
    //   this.errorHandler.context = {context: JSON.stringify(extraData)};
    // }

    if (this.errorHandler) {
      this.errorHandler.report(error);
    }
  }

  public render() {
    const initialContext: ErrorReportingContextType = {
      throwError: this.throwError,
      setUser: this.setUser,
    };

    // when there's not an error, render children untouched
    return (
      <ErrorReportingContext.Provider value={initialContext}>
        {this.props.children}
      </ErrorReportingContext.Provider>
    );
  }
}
