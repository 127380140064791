/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { getURLParameters } from '../utils/index';
import { CareemContext } from './CareemProvider';
import logger from './utils';

const { currency, locale } = getURLParameters();
const initialState = {
  currency: currency || 'GBP',
  locale: locale || 'en-GB',
  theme: 'whiteLabel',
};

export interface SettingsOption {
  label: string;
  shortLabel?: string;
  value: string;
}

type SettingsMap<T extends string> = { [P in T]: SettingsOption[] };

export type Settings = SettingsMap<'currency' | 'locale'>;

export const availableSettings: Settings = {
  currency: [
    { label: 'GBP (£)', value: 'GBP' },
    { label: 'USD ($)', value: 'USD' },
    { label: 'EUR (€)', value: 'EUR' },
    { label: 'NOK (kr)', value: 'NOK' },
    { label: 'CHF (Fr.)', value: 'CHF' },
    { label: 'TL (₺)', value: 'TRY' },
    { label: 'AED (د.إ)', value: 'AED' },
    { label: 'BHD (.د.ب)', value: 'BHD' },
    { label: 'KWD (د.ك)', value: 'KWD' },
    { label: 'QAR (ر.ق)', value: 'QAR' },
    { label: 'SAR (ريال سعودي)', value: 'SAR' },
  ],
  locale: [
    { label: 'English (GB)', value: 'en-GB', shortLabel: 'Eng (GB)' },
    { label: 'English (US)', value: 'en-US', shortLabel: 'Eng (US)' },
    { label: 'French', value: 'fr-FR', shortLabel: 'Fr' },
    { label: 'German', value: 'de-DE', shortLabel: 'Ger' },
    { label: 'Arabic', value: 'ar-AR', shortLabel: 'Ara' },
    { label: 'Turkish', value: 'tr-TR', shortLabel: 'Tur' },
    { label: 'Norwegian', value: 'no-NO', shortLabel: 'Nor' },
    { label: 'Italian', value: 'it-IT', shortLabel: 'Ita' },
  ],
};

export interface SettingsState {
  currency: string;
  locale: string;
}

export interface NewSettingAction<T extends keyof SettingsState> {
  type: 'new-setting';
  setting: T;
  newVal: SettingsState[T];
}

function reducer(
  state: SettingsState,
  action: NewSettingAction<keyof SettingsState>
) {
  switch (action.type) {
    case 'new-setting': {
      const newState = {
        ...state,
        [action.setting]: action.newVal,
      };
      return newState;
    }
    default:
      return state;
  }
}

export interface SettingsActions {
  changeLocale: (newVal: string) => void;
  changeCurrency: (newVal: string) => void;
}

export interface SettingsContextType extends SettingsState {
  availableSettings: Settings;
  actions: SettingsActions;
}

export const SettingsContext = React.createContext<SettingsContextType>({
  availableSettings: {
    currency: [],
    locale: [],
  },
  ...initialState,
  actions: {
    changeCurrency: () => {},
    changeLocale: () => {},
  },
});

export function SettingsProvider({ children }: React.PropsWithChildren<{}>) {
  const [settings, dispatch] = useReducer(
    logger(reducer, 'SettingsProvider'),
    initialState
  );
  const urlParams = getURLParameters();
  const { i18n } = useTranslation();
  const { settings: careemSettings } = useContext(CareemContext);

  useEffect(() => {
    const newLocale =
      careemSettings?.locale || urlParams.locale || i18n.language;
    const newCurrency =
      urlParams.currency || localStorage.getItem('currency') || 'GBP';

    if (settings.locale !== newLocale) {
      dispatch({ type: 'new-setting', setting: 'locale', newVal: newLocale });
    }
    if (i18n.language !== newLocale) {
      i18n.changeLanguage(newLocale);
    }
    if (moment.locale() !== newLocale) {
      moment.locale(newLocale);
    }

    if (settings.currency !== newCurrency) {
      dispatch({
        type: 'new-setting',
        setting: 'currency',
        newVal: newCurrency,
      });
      localStorage.setItem('currency', newCurrency);
    }
  }, []);

  useEffect(() => {
    if (settings.locale === 'ar-AR') {
      document.dir = 'rtl';
    } else {
      document.dir = 'ltr';
    }
  }, [settings.locale]);

  const actions = React.useMemo<SettingsActions>(() => {
    return {
      changeLocale: (newVal: string) => {
        dispatch({ type: 'new-setting', setting: 'locale', newVal });
        i18n.changeLanguage(newVal);
        moment.locale(newVal);
        if (window.zE) {
          window.zE(() => {
            window.zE.setLocale(newVal);
          });
        }
      },
      changeCurrency: (newVal: string) => {
        dispatch({ type: 'new-setting', setting: 'currency', newVal });
        localStorage.setItem('currency', newVal);
      },
    };
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        actions,
        availableSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
