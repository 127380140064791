/* eslint-disable react/destructuring-assignment */
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { UseQueryResult } from 'react-query';
import apis from '../apis';
import { Basket, BasketItem, BasketStatus } from '../definitions/tickitto';
import logger from './utils';

export interface TicketsContextType {
  tickets: { [name: string]: BasketItem };
  actions: TicketsActions;
  query?: UseQueryResult<Basket, unknown>;
}

export const TicketsContext = createContext<TicketsContextType>({
  tickets: {},
  actions: {
    add: () => {},
    remove: () => {},
    removeAll: () => {},
    refreshBasket: () => {},
  },
  query: undefined,
});
/*
  How we store ticket data, per ticket we hold two separate pieces of data, the data we use to diplay the name location ect,
  the second is the data in the format that the backend requires, and we do not do anything with this.
  so for example a ticket will be something like:

  tickets: {
    [ID]: {
      provider: 'prio
      display: [
        {
          name: String,
          quantity: String,
          location?: String,
          price: {
            value: Number,
            currency: String
          }
        }
      ],
      apiData: [
        compiledApiObject
      ]
    }
  }
*/

export interface Price {
  value: number;
  currency: string;
}

export interface Display {
  name: string;
  quantity: string;
  location?: string;
  price: Price;
}

export interface TicketsState {
  tickets: { [name: string]: BasketItem };
}

interface AddTicketAction {
  type: 'add';
  id: string;
  ticket: BasketItem;
}

interface RemoveTicketAction {
  type: 'remove';
  id: string;
}

interface RemoveAllTicketsAction {
  type: 'remove-all';
}

type Action = AddTicketAction | RemoveTicketAction | RemoveAllTicketsAction;

const initialState: TicketsState = {
  tickets: {},
};

export interface TicketsActions {
  add: (id: string, ticket: BasketItem) => void;
  remove: (id: string) => void;
  removeAll: () => void;
  refreshBasket: () => void;
}

function reducer(state: TicketsState, action: Action) {
  switch (action.type) {
    case 'add': {
      const { id } = action;
      const newState = {
        tickets: {
          ...state.tickets,
          [id]: action.ticket,
        },
      };
      return newState;
    }
    case 'remove': {
      const { id } = action;
      const newState = { ...state };
      delete newState.tickets[id];
      return newState;
    }
    case 'remove-all': {
      return initialState;
    }
    default:
      throw new Error('unhandled action was thrown in ticketContext reducer');
  }
}

export function TicketsProvider({ children }: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(
    logger(reducer, 'TicketsProvider'),
    initialState
  );

  const [numOfBasketRefreshes, setNumOfBasketRefreshes] = useState(0);

  const actions = React.useMemo<TicketsActions>(() => {
    return {
      add: (id, ticket) => dispatch({ type: 'add', id, ticket }),
      remove: (id) => dispatch({ type: 'remove', id }),
      removeAll: () => dispatch({ type: 'remove-all' }),
      refreshBasket: () => {
        dispatch({ type: 'remove-all' });
        setNumOfBasketRefreshes(numOfBasketRefreshes + 1);
      },
    };
  }, []);

  const basketContentsQuery = apis.tickitto.useGetBasketContents();

  useEffect(() => {
    // refetch basket content when new ticket is being added/removed
    if (
      basketContentsQuery.isStale &&
      basketContentsQuery.data?.items !== Object.values(state.tickets)
    ) {
      basketContentsQuery.refetch();
    }

    // refresh basket id when the status is CHECKED_OUT or CHECKOUT_PENDING
    if (
      basketContentsQuery.isFetched &&
      (basketContentsQuery.data?.checkout_status === BasketStatus.CHECKED_OUT ||
        basketContentsQuery.data?.checkout_status ===
          BasketStatus.CHECKOUT_PENDING ||
        basketContentsQuery.data?.checkout_status ===
          BasketStatus.CHECKED_OUT_WITH_ERRORS)
    ) {
      localStorage.removeItem('basketId');
      basketContentsQuery.refetch();
    }
  }, [state]);

  useEffect(() => {
    const basketData: Basket | undefined = basketContentsQuery.data;
    if (basketData != null) {
      if (basketData?.items?.length > 0) {
        basketData.items.forEach((elem) => {
          actions.add(elem.id, elem);
        });
      } else {
        actions.removeAll();
      }
    }
  }, [
    basketContentsQuery.isSuccess,
    basketContentsQuery?.data?.items,
    numOfBasketRefreshes,
  ]);

  return (
    <TicketsContext.Provider
      value={{
        ...state,
        tickets: state.tickets,
        actions,
        query: basketContentsQuery,
      }}
    >
      {children}
    </TicketsContext.Provider>
  );
}
