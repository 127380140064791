// prettier-ignore
const defaultTheme = new Map();

defaultTheme.set('primary-color', '#4f5157');
defaultTheme.set('secondary-color', '#4f5157');

// text colors
defaultTheme.set('text-color-default', '@secondary-color');
defaultTheme.set('text-color-light', '#7c7c7c');
defaultTheme.set('text-color-dark', '#000000');
defaultTheme.set('text-color-inverted', '#ffffff');
defaultTheme.set('text-color-highlighted', '@primary-color');

// title colors
defaultTheme.set('title-text-color-default', '@text-color-dark');
defaultTheme.set('title-text-color-light', '@text-color-default');
defaultTheme.set('title-text-color-inverted', '@text-color-inverted');

defaultTheme.set('body-background', '#f5f5f6');
defaultTheme.set('body-background-secondary', '#ffffff');

defaultTheme.set('border-color-base', '@primary-color');
defaultTheme.set('primary-font-family', 'Heebo');
defaultTheme.set('secondary-font-family', 'Montserrat');
defaultTheme.set('inactive-color', '#7c7c7c');

defaultTheme.set('modal-overlay-color', 'rgba(0, 0, 0, 0.5)');

defaultTheme.set('border-radius-base', '1.2rem');

defaultTheme.set('border-width-base', '1px');

defaultTheme.set('cross-icon-color', '#7c7c7c');

defaultTheme.set('input-bg', '#f8f5fa');
defaultTheme.set('input-border-color', '#ebe6ef');
defaultTheme.set('input-border-radius-base', '0.75rem');
defaultTheme.set('input-border-radius-base-mobile', '0.625rem');
defaultTheme.set('input-text-color', '@text-color-default');
defaultTheme.set('input-border-width', '@border-width-base');

defaultTheme.set('messages-border-width', '2px');
defaultTheme.set('messages-bg-opacity', '0.06');
defaultTheme.set('messages-border-radius', '10px');
defaultTheme.set('error-messages', '#fc6666');
defaultTheme.set('success-messages', '#49BF33');
defaultTheme.set('notification-messages', '@primary-color');
defaultTheme.set('notification-bg', '@input-bg');

defaultTheme.set('layout-header-background', '@primary-color');
defaultTheme.set('layout-body-background', '@body-background');
defaultTheme.set('layout-footer-background', '@primary-color');

defaultTheme.set('btn-default-color', '@primary-color');
defaultTheme.set('btn-default-ghost-color', '#7c7c7c');
defaultTheme.set('btn-default-ghost-border', '#7c7c7c');
defaultTheme.set('btn-default-border', '@btn-default-color');
defaultTheme.set('btn-secondary-text-color', '@text-color-inverted');
defaultTheme.set('btn-link-color', '@secondary-color');
defaultTheme.set('btn-border-radius', '@input-border-radius-base');
defaultTheme.set('btn-border-width', '0.25rem');

defaultTheme.set('shadow-color', 'rgba(0, 0, 0, 0.1)');
defaultTheme.set('shadow-color-hover', 'rgba(0, 0, 0, 0.16)');
defaultTheme.set('bar-shadow-color', '#0000000a');

defaultTheme.set('header-text-color', '@text-color-dark');
defaultTheme.set('header-icon-color', '@primary-color');
defaultTheme.set('header-subsection-bg-color', '@primary-color');

defaultTheme.set('pagination-item-bg-active', '@primary-color');
defaultTheme.set('pagination-border-color', '@primary-color');
defaultTheme.set('pagination-text-color', '@text-color-highlighted');
defaultTheme.set('pagination-text-color-active', '@text-color-inverted');
defaultTheme.set('pagination-ellipsis-color', '@secondary-color');
defaultTheme.set('pagination-arrow-color', '@secondary-color');
defaultTheme.set('pagination-step-arrow-color', '@text-color-dark');

defaultTheme.set('dropdown-text-color', '@text-color-default');
defaultTheme.set('dropdown-border-color', '@primary-color');
defaultTheme.set('dropdown-arrow-color', '@primary-color');
defaultTheme.set('dropdown-arrow-dark-color', '@text-color-dark');
defaultTheme.set('dropdown-arrow-secondary-color', '@text-color-inverted');
defaultTheme.set('dropdown-secondary-color', '@text-color-inverted');
defaultTheme.set('dropdown-border-radius', '0.7rem');

defaultTheme.set('breadcrumb-last-item-color', '@text-color-inverted');
defaultTheme.set('breadcrumb-separator-color', '@text-color-inverted');
defaultTheme.set('breadcrumb-text-color', '@text-color-inverted');

defaultTheme.set('event-btn-bg-color', '@body-background');

defaultTheme.set('steps-bg-color', '@text-color-inverted');
defaultTheme.set('steps-text-color', '@text-color-dark');
defaultTheme.set('steps-secondary-text-color', '@text-color-inverted');
defaultTheme.set('overlay-bg-color', '@text-color-dark');

defaultTheme.set('card-actions-background', '@input-bg');
defaultTheme.set('card-title-text-color', '@text-color-default');
defaultTheme.set('card-large-title-text-color', '@text-color-dark');
defaultTheme.set('card-paragraph-color', '@secondary-color');
defaultTheme.set('card-tag-text-color', '@text-color-inverted');
defaultTheme.set('card-tag-background-color', '#3fb729');
defaultTheme.set('card-location-text-color', '@text-color-light');
defaultTheme.set('card-secondary-bg-color', '@text-color-inverted');
defaultTheme.set('card-border-color', '@input-border-color');
defaultTheme.set('card-shadow-color', '#0000000f');
defaultTheme.set('card-border-radius', '@border-radius-base');
defaultTheme.set('card-border-width', '@border-width-base');
defaultTheme.set('price-text-color', '@primary-color');
defaultTheme.set('menu-bg-color', '@text-color-inverted');
defaultTheme.set('checkbox-border-hover-color', '@primary-color');
defaultTheme.set('checkbox-color', '@input-bg');
defaultTheme.set('checkbox-check-color', '@primary-color');
defaultTheme.set('checkbox-check-bg', '@input-bg');
defaultTheme.set('checkbox-border-radius', '0.3rem');
defaultTheme.set('checkbox-border-width', '0.2rem');

defaultTheme.set('radio-button-bg', '@primary-color');
defaultTheme.set('radio-button-checked-bg', '@primary-color');

// calendar
defaultTheme.set('picker-primary-color', '@primary-color');
defaultTheme.set('picker-bg-color', '@body-background-secondary');
defaultTheme.set('picker-active-bg-color', '@picker-primary-color');
defaultTheme.set('picker-disabled-bg-color', '@input-bg');
defaultTheme.set('picker-text-color', '@text-color-default');
defaultTheme.set('picker-secondary-text-color', '@text-color-default');
defaultTheme.set('picker-border-color', '@picker-primary-color');
defaultTheme.set('picker-cell-border-thickness', '1px');
defaultTheme.set('picker-cell-border-radius', '0.25rem');
defaultTheme.set('picker-transparent-bg-color', 'transparent');
defaultTheme.set('picker-separator-color', 'rgba(122, 73, 160, 0.34)');

defaultTheme.set('form-text-secondary-color', '@secondary-color');
defaultTheme.set('form-text-color', '@primary-color');

defaultTheme.set('footer-title-color', '@text-color-inverted');
defaultTheme.set('footer-text-color', '@text-color-inverted');
defaultTheme.set('footer-btn-link-color', '@text-color-inverted');
defaultTheme.set('footer-border-color', '@text-color-inverted');
defaultTheme.set('footer-select-bg-color', '#FFFFFF0F');

defaultTheme.set('collapse-bg-color', '@text-color-inverted');
defaultTheme.set('collapse-arrow-color', '@primary-color');

defaultTheme.set('separate-line-color', '@input-border-color');

defaultTheme.set('secondary-icon-color', '@text-color-inverted');

defaultTheme.set('widget-main-panel-bg', '@body-background-secondary');
defaultTheme.set('widget-main-panel-border', '2px solid @input-border-color');
defaultTheme.set('widget-main-panel-radius', '@border-radius-base');
defaultTheme.set('widget-main-panel-box-shadow', '0px 8px 16px #0000000F');
defaultTheme.set(
  'widget-content-panel-padding-desktop',
  '0 2.8125rem 4.6875rem'
);
defaultTheme.set('widget-content-panel-padding-mobile', '0 1rem 2.375rem');

export default defaultTheme;
