/* eslint-disable react/destructuring-assignment */
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  createContext,
} from 'react';
import { useTracking } from 'react-tracking';
import apis from '../apis/index';
import tktApi from '../apis/instances/tickitto-careem';
import icons, { IconsDataType } from '../components/Icon/data';
import LoadingSpinner from '../components/Tiny/LoadingSpinner';
import { ProfileCustomisations, Theme } from '../definitions/tickitto';
import defaultTheme from '../styles/themes/default';
import {
  applyTheme,
  applyThemeFonts,
  evalThemeIcons,
  evalThemeMap,
  verifyIncomingTheme,
} from '../styles/themes/index';
import { getURLParameters, isWidgetMode } from '../utils';
import { CareemContext } from './CareemProvider';
import { ErrorReportingContext } from './ErrorReportingProvider';
import { SettingsContext } from './SettingsProvider';

interface ThemeContextType extends Omit<Theme, 'icons' | 'variables'> {
  name: string;
  theme: Record<string, string>;
  icons: IconsDataType;
  customisations?: ProfileCustomisations;
}

const initalThemeVal = {
  name: '',
  theme: evalThemeMap(defaultTheme) as Record<string, string>,
  icons: evalThemeIcons(icons),
  fonts: [],
  images: {},
  menu_buttons: [],
  title: '',
  favicon: '',
  customisations: {},
};

function scriptExists(url: string) {
  return document.querySelectorAll(`script[src="${url}"]`).length > 0;
}

export const ThemeContext = createContext<ThemeContextType>(initalThemeVal);

interface ThemeProviderProps {
  children?: JSX.Element | null;
}

export function ThemeProvider({ children = null }: ThemeProviderProps) {
  const { throwError } = useContext(ErrorReportingContext);
  const { actions, currency, locale } = useContext(SettingsContext);
  const [currThemeVars, setThemeVars] = useState<ThemeContextType>({
    name: '',
    theme: evalThemeMap(defaultTheme) as Record<string, string>,
    icons: evalThemeIcons(icons),
    fonts: [],
    images: {},
    menu_buttons: [],
    title: '',
    favicon: '',
    customisations: {},
  });
  const { userDetails, active: isCareem } = useContext(CareemContext);

  const isThemeApplied = useRef(false);
  const [zeScriptLoaded, setZeScriptLoaded] = useState(false);
  const [aurycScriptLoaded, setAurycScriptLoaded] = useState(false);
  const { trackEvent } = useTracking();

  const { profile } = getURLParameters();

  const profileQuery = apis.tickitto.useGetProfile(
    profile ? { profileName: profile } : {}
  );

  useEffect(() => {
    if (!profileQuery.isFetched) {
      document.title = 'Loading...';
    } else {
      document.title = profileQuery.data?.theme.title || 'Tickitto';
    }
  }, [profileQuery.isFetched]);

  // Inject Zendesk script and custom scripts after fetching the theme (as it is not possible to change on fly)
  useEffect(() => {
    if (profileQuery.isFetched) {
      const { data } = profileQuery;
      // either use the profile's zendesk script or the default (Tickitto) one
      const zendeskScript =
        data?.customisations?.zendesk_script ||
        'https://static.zdassets.com/ekr/snippet.js?key=45eb7bc6-7a12-489f-bf62-c5ae86e29b0a';

      if (!scriptExists(zendeskScript) && !isWidgetMode()) {
        const zeScript = document.createElement('script');
        zeScript.src = zendeskScript;
        zeScript.id = 'ze-snippet';
        zeScript.defer = true;
        zeScript.onload = () => {
          window.zE(() => {
            window.zE.setLocale(localStorage.getItem('i18nextLng'));
            if (data?.name === 'careem_tikety') {
              window?.zE('webWidget', 'hide');
            }
            setZeScriptLoaded(true);
          });
        };

        window.zESettings = {
          webWidget: {
            color: {
              theme: currThemeVars.theme['primary-color'],
              launcher: currThemeVars.theme['input-bg'],
              launcherText: currThemeVars.theme['input-text-color'],
              button: currThemeVars.theme['btn-default-color'],
              resultLists: currThemeVars.theme['primary-color'],
              header: currThemeVars.theme['primary-color'],
              articleLinks: currThemeVars.theme['primary-color'],
            },
            chat: {
              departments: {
                enabled: [],
              },
            },
          },
        };

        if (isCareem && userDetails) {
          window.zESettings.authenticate = {
            chat: {
              jwtFn: (callback: any) => {
                tktApi
                  .get('/jwt', {
                    params: {
                      user_id: userDetails?.id,
                    },
                  })
                  .then((res) => {
                    const text = res.data;
                    callback(text);
                  });
              },
            },
          };
        }

        document.head.appendChild(zeScript);
      }

      // Inject custom scripts if any
      if (
        data &&
        data.customisations &&
        data.customisations.custom_scripts &&
        data.customisations.custom_scripts.length > 0
      ) {
        data.customisations.custom_scripts.forEach((customScript, index) => {
          if (!scriptExists(customScript.src)) {
            const script = document.createElement('script');
            script.src = customScript.src;
            script.id = `custom-script-${index}`;
            script.defer = true;
            document.head.append(script);
            script.onload = () => {
              if (customScript.src.includes('auryc')) {
                setAurycScriptLoaded(true);
              }
            };
          }
        });
      }
    }
  }, [profileQuery.isFetched, profileQuery.data]);

  useEffect(() => {
    if (isCareem && userDetails?.id && aurycScriptLoaded && window.auryc) {
      window.auryc?.identify(userDetails.id as string);
      setAurycScriptLoaded(false);
    }
  }, [userDetails, isCareem, aurycScriptLoaded]);

  useEffect(() => {
    if (isCareem && userDetails?.id) {
      trackEvent({
        user_id: userDetails?.id,
      });
    }
  }, [userDetails, isCareem]);

  useEffect(() => {
    const { data } = profileQuery;

    if (data) {
      if (
        data.default_currency &&
        data.default_currency !== currency &&
        !getURLParameters().newTab
      ) {
        actions.changeCurrency(data.default_currency);
      }
      if (
        zeScriptLoaded &&
        data.default_language &&
        data.default_language !== locale &&
        !getURLParameters().newTab
      ) {
        actions.changeLocale(data.default_language);
      }
    }
  }, [zeScriptLoaded, profileQuery.data, userDetails]);

  useMemo(() => {
    if (
      profileQuery.isSuccess &&
      profileQuery.data != null &&
      !isThemeApplied.current
    ) {
      isThemeApplied.current = true;
      const { data } = profileQuery;

      const profileName = data.name;

      // we still use the merge logic as a fallback
      const newTheme: Record<string, string> = evalThemeMap(
        defaultTheme,
        data.theme?.variables
      );

      const newThemeIcons: IconsDataType = evalThemeIcons(
        icons,
        data.theme?.icons
      );

      const verificationRes = verifyIncomingTheme(defaultTheme, newTheme);

      if (verificationRes != null) {
        throwError(verificationRes);
      }

      // Change title and favicon of the page
      document.title = data.theme?.title || document.title;
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = data.theme?.favicon || '';

      setThemeVars({
        ...currThemeVars,
        name: profileName,
        theme: newTheme,
        icons: newThemeIcons,
        fonts: data.theme?.fonts,
        images: data.theme?.images,
        menu_buttons: data.theme?.menu_buttons,
        customisations: data.customisations,
      });
      applyTheme(newTheme);
      applyThemeFonts(data.theme?.fonts);
    } else if (profileQuery.isError && !isThemeApplied.current) {
      isThemeApplied.current = true;
      const evaluatedTheme = evalThemeMap(defaultTheme);

      applyTheme(evaluatedTheme);
      setThemeVars({ ...currThemeVars, theme: evaluatedTheme });
      throwError(
        'There was an error fetching the requested theme, check if the theme id is correct.',
        profileQuery.error
      );
    }
  }, [profileQuery.isSuccess, profileQuery.isError, currThemeVars, throwError]);

  // Apply the default theme if the profile query haven't fetched yet and show generic loading indicator
  if (!isThemeApplied.current) {
    const evaluatedTheme = evalThemeMap(defaultTheme);
    applyTheme(evaluatedTheme);
    return (
      <div style={{ height: '100vh' }}>
        <LoadingSpinner type="dots" fullPage />
      </div>
    );
  }

  return (
    <ThemeContext.Provider value={currThemeVars}>
      {children}
    </ThemeContext.Provider>
  );
}
