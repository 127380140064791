import { useStripe } from '@stripe/react-stripe-js';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { PaymentMethod } from '../../components/CheckoutSteps/PaymentStep';
import { CareemContext } from '../../contextProviders/CareemProvider';
import { ErrorReportingContext } from '../../contextProviders/ErrorReportingProvider';
import {
  Basket,
  BasketAddData,
  BasketStatus,
  Customer,
} from '../../definitions/tickitto';
import { getURLParameters } from '../../utils';
import tktApi from '../instances/tickitto';

import { UserInfo } from '../../components/CheckoutSteps/UserDetailsCard';
import { SettingsContext } from '../../contextProviders/SettingsProvider';
import { splitName } from '../../pages/CheckoutPage/utils';

export function setBasketId(id: string) {
  localStorage.setItem('basketId', id);
}

const createBasket = async (careem: boolean) => {
  const { data } = await tktApi.post(
    'basket/',
    {},
    { params: careem ? { payment_method_type: 'careem_pay' } : {} }
  );

  const latestStoredVal = localStorage.getItem('basketId');
  if (latestStoredVal !== null) {
    return { _id: latestStoredVal };
  }
  setBasketId(data._id);
  return data;
};

export function useGetBasketId() {
  const { active: careem } = useContext(CareemContext);

  return useQuery(
    ['basket/', 'POST'],
    async () => {
      let returnData = null;

      const urlBasketId = getURLParameters().basketId;
      if (urlBasketId != null) {
        setBasketId(urlBasketId);
        return { _id: urlBasketId };
      }

      const storedVal = localStorage.getItem('basketId');
      if (storedVal !== null) {
        returnData = { _id: storedVal };
      }

      if (returnData == null) {
        returnData = await createBasket(careem);
      }

      return returnData;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
}

function useGetBasketContents() {
  const { data: idData } = useGetBasketId();
  const { userDetails, active: careem } = useContext(CareemContext);
  const basketId = idData?._id;

  return useQuery<Basket>(
    ['basket', 'GET', basketId],
    async () => {
      if (basketId) {
        const { data } = await tktApi.get('basket/', {
          params: { basket_id: basketId, user_id: userDetails?.id },
        });
        if (data.checkout_status === BasketStatus.PAYMENT_FAILED) {
          localStorage.removeItem('basketId');
          const newBasket = await createBasket(careem);
          return newBasket;
        }
        return data;
      }

      return {};
    },
    {
      enabled: !!basketId,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const statusCode = (error as AxiosError).response?.status;
        if (statusCode === 404 && failureCount < 1) {
          console.warn('Basket not found');
          localStorage.removeItem('basketId');
          return false;
        }
        if (failureCount > 3) {
          return false;
        }
        return true;
      },
    }
  );
}

function useAddItemToBasket() {
  const { data: idData } = useGetBasketId();
  const basketId = idData?._id;

  return useMutation(
    async ({
      sessionId,
      basketData,
    }: {
      sessionId: string;
      basketData: BasketAddData;
    }) => {
      const body: BasketAddData = {
        product_id: basketData.product_id,
        booking_options: basketData.booking_options,
        availability_id: basketData.availability_id,
        display_data: basketData.display_data,
        product_options: basketData.product_options,
      };

      const { data } = await tktApi.post('/basket/add', body, {
        params: {
          basket_id: basketId,
          availability_session_id: sessionId,
        },
      });
      return data;
    },
    { retry: 3 }
  );
}

function useRemoveBasketItem(onSuccess?: (data: any, variables: any) => void) {
  const { data: idData } = useGetBasketId();
  const basketId = idData?._id;

  return useMutation(
    async ({ itemId }: { itemId: string }) => {
      const { data } = await tktApi.post(
        'basket/remove',
        {},
        {
          params: {
            basket_id: basketId,
            item_id: itemId,
          },
        }
      );

      return data;
    },
    {
      onSuccess,
      onError: (error) => {
        const statusCode = (error as AxiosError).response?.status;
        if (statusCode === 402) {
          console.warn('Unable to remove item from basket.');
          localStorage.removeItem('basketId');
        }
      },
    }
  );
}

function useStripeCheckoutBasket(
  onSuccess: any, // (intent_secret: string) => void,
  onError: any // (err: any) => void
) {
  const stripe = useStripe();
  const { currency: currencyWanted } = useContext(SettingsContext);
  const { data: idData } = useGetBasketId();
  const basketId = idData?._id;
  const { throwError } = useContext(ErrorReportingContext);

  return useMutation(
    async ({
      userData,
      method,
    }: {
      userData: UserInfo;
      method: PaymentMethod;
    }) => {
      if (!stripe) {
        throw new Error('Stripe API missing');
      }

      try {
        switch (method.type) {
          // when checkingut with card number
          case 'stripe.cardNumber': {
            const { data: stripeData } = await tktApi.post(
              'basket/stripe_object',
              {
                email: userData.email,
                phone_number: userData.phone,
                first_name: userData.firstName,
                last_name: userData.lastName,
                currency: currencyWanted === 'JUN' ? 'GBP' : currencyWanted,
              },
              {
                params: {
                  basket_id: basketId,
                },
              }
            );

            if (!stripeData) {
              throw new Error('No stripe data recieved');
            }

            const confirmRes = await stripe.confirmCardPayment(
              stripeData.stripe_payment_intent_secret,
              {
                payment_method: {
                  card: method.stripeCardElement,
                  billing_details: {
                    name: `${userData.firstName!} ${userData.lastName!}`,
                    email: userData.email,
                    phone: userData.phone!,
                  },
                },
              }
            );
            // throw error if something did not go through
            if (confirmRes.error != null && confirmRes.error.code != null) {
              throw Error(confirmRes.error.message);
            }

            return {
              user_info: userData,
              intent_secret: stripeData.stripe_payment_intent_secret,
            };
          }

          // when checking out with apple pay etc
          case 'stripe.paymentRequest': {
            const billingDetails = method.event.paymentMethod.billing_details;

            const name = billingDetails.name || '';
            const [firstName, lastName] = splitName(name);

            const userDetails = {
              first_name: firstName,
              last_name: lastName,
              phone_number: billingDetails.phone || '',
              email: billingDetails.email || '',
            };

            const { data: stripeData } = await tktApi.post(
              'basket/stripe_object',
              {
                email: userData.email,
                phone_number: userData.phone,
                first_name: userData.firstName,
                last_name: userData.lastName,
              },
              {
                params: {
                  basket_id: basketId,
                },
              }
            );

            if (!stripeData) {
              throw new Error('No stripe data recieved');
            }

            try {
              await stripe.confirmCardPayment(
                stripeData.stripe_payment_intent_secret,
                {
                  payment_method: method.event.paymentMethod.id,
                }
              );
              method.event.complete('success');
            } catch (err) {
              method.event.complete('fail');
            }

            return {
              user_info: userDetails,
              intent_secret: stripeData.stripe_payment_intent_secret,
            };
          }

          default:
            throw new Error('Unhandled payment method');
        }
      } catch (error) {
        const err = error as any;

        if (err.response && err.response.data) {
          if (err.response.data?.detail?.[0].msg) {
            throwError(err.response.data?.detail?.[0].msg);
            throw Error(err.response.data.detail?.[0].msg);
          } else {
            throwError(err.response.data?.detail?.[0].msg);
            throw Error(
              (error as AxiosError).message || 'Unexpected Error Occurred'
            );
          }
        } else {
          throw Error(
            (error as AxiosError).message || 'Unexpected Error Occurred'
          );
        }
      }
    },
    {
      onSuccess,
      onError,
    }
  );
}

function useCareemCheckoutBasket(
  onSuccess: any, // (intent_secret: string) => void,
  onError: any // (err: any) => void
) {
  const { currency: currencyWanted } = useContext(SettingsContext);
  const { data: idData } = useGetBasketId();
  const { userDetails } = useContext(CareemContext);
  const { throwError } = useContext(ErrorReportingContext);
  const basketId = idData?._id;

  return useMutation(
    async ({ userData }: { userData: UserInfo }) => {
      if (!userDetails || !userDetails.id) {
        throwError('Careem user details missing', userDetails);
        throw new Error('User ID missing');
      }

      try {
        const { data: careemPayData } = await tktApi.post(
          'basket/careem_pay_object',
          {
            email: userData.email,
            phone_number: userData.phone,
            first_name: userData.firstName,
            last_name: userData.lastName,
            currency: currencyWanted,
            careem_user_id: userDetails.id,
          },
          {
            params: {
              basket_id: basketId,
            },
          }
        );
        if (!careemPayData) {
          throwError('No CareemPay data recieved');
          throw new Error('No CareemPay data recieved');
        }

        const { invoice_id, currency, amount } = careemPayData;
        const roundedAmount = (amount as number).toFixed(2);

        const callbackUrl = encodeURIComponent(
          `${window.location.origin}/checkout-success/${basketId}`
        );

        // TODO will be used in the app
        const careemPayUrl = `careem://app.careem.com/pay?invoiceId=${invoice_id}&amount=${roundedAmount}&currency=${currency}&callbackUrl=${callbackUrl}`;

        // Uncomment below if you want to test on dev (without deeplink)
        // const confirmRes = await careemTktApi.post(
        //   '/payment',
        //   {},
        //   {
        //     params: {
        //       invoice_id,
        //     },
        //   }
        // );

        // if (!confirmRes?.data || !confirmRes.data.success) {
        //   throw Error('No success');
        // }

        return {
          success: true,
          url: careemPayUrl,
        };
      } catch (error) {
        const err = error as any;

        if (err.response && err.response.data) {
          if (err.response.data?.detail?.[0].msg) {
            throwError(err.response.data?.detail?.[0].msg);
            throw Error(err.response.data.detail?.[0].msg);
          } else {
            throwError(err.response.data?.detail?.[0].msg);
            throw Error(
              (error as AxiosError).message || 'Unexpected Error Occurred'
            );
          }
        } else {
          throw Error(
            (error as AxiosError).message || 'Unexpected Error Occurred'
          );
        }
      }
    },
    {
      onSuccess,
      onError,
    }
  );
}

function useUpdateBasketUserInfo() {
  const { data: idData } = useGetBasketId();
  const basketId = idData?._id;

  return useMutation(async ({ userData }: { userData: UserInfo }) => {
    const { data } = await tktApi.post(
      'basket/customer',
      {
        email: userData.email,
        phone_number: userData.phone,
        first_name: userData.firstName,
        last_name: userData.lastName,
      },
      {
        params: {
          basket_id: basketId,
        },
      }
    );

    return data;
  });
}

// posts user data to our api and gets stripe data necessary to make a payment
function useMaketStripeData() {
  const { data: idData } = useGetBasketId();
  const basketId = idData?._id;
  return useMutation(async ({ userData }: { userData: Customer }) => {
    const { data } = await tktApi.post('basket/stripe_object', userData, {
      params: {
        basket_id: basketId,
      },
    });

    return data;
  });
}

function useAddPromoCode(onSuccess?: (data: any, variables: any) => void) {
  const { locale } = useContext(SettingsContext);
  const { userDetails } = useContext(CareemContext);

  const { data: idData } = useGetBasketId();
  const basketId = idData?._id;

  return useMutation(
    async ({ promocode }: { promocode: string }) => {
      const { data } = await tktApi.put(
        'basket/promocode',
        {},
        {
          params: { basket_id: basketId, promocode, user_id: userDetails?.id },
          headers: { 'accept-language': locale || 'en' },
        }
      );

      return data;
    },
    {
      retry: (failureCount, error) => {
        const statusCode = (error as AxiosError).response?.status;
        if (
          statusCode === 404 ||
          statusCode === 409 ||
          statusCode === 422 ||
          failureCount >= 3
        ) {
          return false;
        }

        return true;
      },
      onSuccess,
    }
  );
}

function useRemovePromoCode(onSuccess?: (data: any, variables: any) => void) {
  return useMutation(
    async ({ promocode }: { promocode: string }) => {
      const basketId = localStorage.getItem('basketId');

      const { data } = await tktApi.delete('basket/promocode', {
        params: {
          basket_id: basketId,
          promocode,
        },
      });

      return data;
    },
    {
      retry: (failureCount, error) => {
        if (failureCount > 1) {
          return false;
        }
        return true;
      },
      onSuccess,
    }
  );
}

const client = {
  useGetBasketId,
  useGetBasketContents,
  useAddItemToBasket,
  useRemoveBasketItem,
  useMaketStripeData,
  useStripeCheckoutBasket,
  useCareemCheckoutBasket,
  useUpdateBasketUserInfo,
  useAddPromoCode,
  useRemovePromoCode,
};

export default client;
