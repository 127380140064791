import { ResizeSensor, ResizeSensorCallback } from 'css-element-queries';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { dispatchPostMessage } from '../utils';

export enum BreakPoints {
  MOBILE = 480,
  TABLET = 767,
  LANDSCAPE_TABLET = 991,
  DESKTOP = 1200,
}

export interface AppViewContextType {
  isMobile: boolean;
  isTablet: boolean;
  isLandscapeTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  currView: string | null;
}

interface AppViewContextInternal extends AppViewContextType {
  useViewSizeImpl: () => { width: number; height: number };
}

export const AppViewContext = createContext<AppViewContextType>({
  isMobile: false,
  isTablet: false,
  isLandscapeTablet: false,
  isDesktop: false,
  isLargeDesktop: false,
  currView: null,
});

function getElementSize(element: HTMLElement) {
  if (!element.getBoundingClientRect) {
    return {
      width: element.offsetWidth,
      height: element.offsetHeight,
    };
  }

  const rect = element.getBoundingClientRect();
  return {
    width: Math.round(rect.width),
    height: Math.round(rect.height),
  };
}

export function AppViewProvider({ children }: React.PropsWithChildren<{}>) {
  const appContainerRef = useRef<HTMLDivElement | null>(null);
  const resizeSens = useRef<ResizeSensor | null>(null);
  const [currView, setView] = useState<string | null>(null);
  const currHeight = useRef(0);
  const resizeListeners = useRef<ResizeSensorCallback[]>([]);

  useEffect(() => {
    const appRef = appContainerRef.current;
    const updateResize: ResizeSensorCallback = (dimensions) => {
      if (dimensions.height !== currHeight.current) {
        if (document.getElementsByClassName('encore-mobile').length > 0) {
          const encoreHeight = 550;
          dispatchPostMessage('NEW_HEIGHT', { height: encoreHeight });
          currHeight.current = encoreHeight;
          return;
        }
        dispatchPostMessage('NEW_HEIGHT', { height: dimensions.height });
        currHeight.current = dimensions.height;
      }

      const currWidth = dimensions.width;
      if (currWidth <= BreakPoints.MOBILE && currView !== 'mobile') {
        setView('mobile');
      } else if (
        currWidth > BreakPoints.MOBILE &&
        currWidth <= BreakPoints.TABLET &&
        currView !== 'tablet'
      ) {
        setView('tablet');
      } else if (
        currWidth > BreakPoints.TABLET &&
        currWidth <= BreakPoints.LANDSCAPE_TABLET &&
        currView !== 'landscape-tablet'
      ) {
        setView('landscape-tablet');
      } else if (
        currWidth > BreakPoints.LANDSCAPE_TABLET &&
        currWidth <= BreakPoints.DESKTOP &&
        currView !== 'desktop'
      ) {
        setView('desktop');
      } else if (
        currWidth > BreakPoints.DESKTOP &&
        currView !== 'large-desktop'
      ) {
        setView('large-desktop');
      }

      // Update listener dimensions
      resizeListeners.current.forEach((listener) => {
        listener(dimensions);
      });
    };
    if (resizeSens.current == null) {
      // eslint-disable-next-line no-new
      resizeSens.current = appRef && new ResizeSensor(appRef, updateResize);
    }

    // (Re-)initialise listener dimensions
    if (appRef) {
      const dimensions = getElementSize(appRef);
      resizeListeners.current.forEach((listener) => {
        listener(dimensions);
      });
    }

    return () => {
      resizeSens.current?.detach(updateResize);
      resizeSens.current = null;
    };
  }, [currView]);

  const viewState: AppViewContextInternal = {
    isMobile: currView === 'mobile',
    isTablet: currView === 'tablet' || currView === 'mobile',
    isLandscapeTablet:
      currView === 'landscape-tablet' ||
      currView === 'tablet' ||
      currView === 'mobile',
    isDesktop:
      currView === 'desktop' ||
      currView === 'landscape-tablet' ||
      currView === 'tablet' ||
      currView === 'mobile',
    isLargeDesktop: currView === 'large-desktop',
    currView,

    /** @internal */
    useViewSizeImpl: function useViewSizeImpl() {
      const initialSize = appContainerRef.current
        ? getElementSize(appContainerRef.current)
        : { width: 0, height: 0 };
      const [dimensions, setDimensions] = useState(initialSize);

      useEffect(() => {
        const onResize: ResizeSensorCallback = function onResize({
          width,
          height,
        }) {
          setDimensions({ width, height });
        };

        resizeListeners.current = [...resizeListeners.current, onResize];
        return () => {
          const idx = resizeListeners.current.indexOf(onResize);
          if (idx > -1) {
            const arr = [...resizeListeners.current];
            arr.splice(idx, 1);
            resizeListeners.current = arr;
          }
        };
      }, []);
      return dimensions;
    },
  };

  return (
    <AppViewContext.Provider value={viewState}>
      <div className="dimensions-watcher" ref={appContainerRef}>
        {children}
      </div>
    </AppViewContext.Provider>
  );
}

export function useViewSize() {
  const { useViewSizeImpl } = useContext(
    AppViewContext
  ) as AppViewContextInternal;
  return useViewSizeImpl();
}
