import Cookies from 'js-cookie';
import React, { createContext, useEffect, useState } from 'react';

const localeMappings = {
  en: 'en-GB',
  ar: 'ar-AR',
};

interface UserDetailsType {
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  phone_number: string;
  id: string;
}

interface SettingsType {
  locale: string;
}

export interface CareemContextType {
  userDetails?: UserDetailsType;
  settings?: SettingsType;
  active: boolean;
}
export const CareemContext = createContext<CareemContextType>({
  active: false,
  settings: undefined,
  userDetails: undefined,
});

export function CareemProvider({ children }: React.PropsWithChildren<{}>) {
  const [active, setActive] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetailsType>();
  const [settings, setSettings] = useState<SettingsType>();

  const cookie = Cookies.get('careem_session');

  useEffect(() => {
    if (window.location.hostname.includes('careem.tickitto') && !active) {
      setActive(true);
      localStorage.removeItem('basketId');
    }
  }, [window.location.hostname]);

  useEffect(() => {
    if (active) {
      if (cookie) {
        const decoded = atob(cookie);
        const parsed = JSON.parse(decoded);
        if (parsed) {
          const {
            email,
            family_name,
            given_name,
            name,
            phone_number,
            accept_language,
            id,
          } = parsed;

          setUserDetails({
            email,
            family_name,
            given_name,
            name,
            phone_number,
            id,
          });
          setSettings({ locale: localeMappings[accept_language] });
        }
      } else {
        console.warn('No cookie found!', cookie);
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
          setTimeout(() => {
            window.location.replace(
              'https://identity.qa.careem-engineering.com/authorize?client_id=8f28789b-b2d7-4d81-bff7-9e50cbfe3bcc&response_type=code&redirect_uri=https://dev.careem.tickitto.tech/oauth/callback%3Fcountry%3DAE&scope=profile%20openid%20phone%20email'
            );
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.replace(
              'https://identity.careem.com/authorize?client_id=a6fd0e13-295a-4c4d-92ff-61fa80d05248&response_type=code&redirect_uri=https://careem.tickitto.tech/oauth/callback%3Fcountry%3DAE&scope=profile%20openid%20phone%20email'
            );
          }, 2000);
        }
      }
    }
  }, [active, cookie]);

  const values = {
    userDetails,
    settings,
    active,
  };

  return (
    <CareemContext.Provider value={values}>{children}</CareemContext.Provider>
  );
}
