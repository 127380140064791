import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { getNewUrl, getURLParameters } from '../../utils';
import Button, { openInNewTab } from '../Button';
import Col from '../Tiny/Col';
import Row from '../Tiny/Row';
import './style.scss';

export interface ErrorOverlayProps {
  content: React.ReactNode;
  showButtonWithText?: string;
}

export default function ErrorOverlay({
  content,
  showButtonWithText,
}: ErrorOverlayProps) {
  const history = useHistory();
  const { profile, promocode, currency, newTab } = getURLParameters();

  return (
    <Col className="error-overlay" justify="center">
      {content}
      {showButtonWithText != null && (
        <>
          <Row>
            <Button
              type="primary"
              text={showButtonWithText}
              isWithAnimatedArrow
              handleOnClick={() => {
                history.push(
                  getNewUrl(
                    ROUTES.HOME_PAGE,
                    { profile, promocode, currency, newTab },
                    false
                  )
                );
              }}
              handleOnAuxClick={() => {
                openInNewTab(
                  getNewUrl(
                    ROUTES.HOME_PAGE,
                    { profile, promocode, currency, newTab },
                    false
                  )
                );
              }}
            />
          </Row>
        </>
      )}
    </Col>
  );
}
