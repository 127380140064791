import {
  useQuery,
  useQueryClient,
  QueryClient,
  UseQueryResult,
} from 'react-query';
import moment from 'moment';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import tktApi from '../instances/tickitto';
import { useGetBasketId } from './basket';
import { dateFormat } from '../../contextProviders/EventsProvider';
import {
  AvailabilityFlow,
  AvailabilityFlowProduct,
  AvailabilitySession,
  AvailabilitySlot,
  AvailProduct,
  BookingOption,
  DayAvailability,
  NiceTicketData,
  OptionChoice,
} from '../../definitions/tickitto';
import { SettingsContext } from '../../contextProviders/SettingsProvider';

function useGetAvailability(eventId: string, getFromDBCache = false) {
  const { data: idData } = useGetBasketId();
  const basketId = idData?._id;

  return useQuery(
    [
      'availability',
      { method: 'GET', basket_id: basketId, event_id: eventId, getFromDBCache },
    ],
    async () => {
      const { data } = await tktApi.get('availability/', {
        params: {
          basket_id: basketId,
          event_id: eventId,
          allow_cache: getFromDBCache,
        },
      });
      return data;
    },
    { enabled: !!basketId, staleTime: 1000 * 60 * 11, cacheTime: 0 } // 11min
  );
}

function getAllSessionQueries(
  queryClient: QueryClient,
  sessionId: string | undefined
): AvailabilitySession[] {
  if (!sessionId) {
    return [];
  }
  return queryClient
    .getQueryCache()
    .findAll([
      'availability/session',
      {
        session_id: sessionId,
      },
    ])
    .filter((query) => query.state.data != null)
    .map((query) => query.state.data as AvailabilitySession);
}

function getAllDayQueries(
  queryClient: QueryClient,
  sessionId: string | undefined
): DayAvailability[] {
  if (!sessionId) {
    return [];
  }
  return queryClient
    .getQueryCache()
    .findAll([
      'availability/day',
      {
        session_id: sessionId,
      },
    ])
    .filter((query) => query.state.data != null)
    .map((query) => query.state.data as DayAvailability);
}

export interface AvailabilitySessionOptions {
  activeMonth: moment.Moment;
  activeDay?: moment.Moment | null;
  activeQuantity?: number | null;
  dateRange?: [moment.Moment, moment.Moment];
  allowCache?: boolean;
  t2?: string | null;
  handleError?: () => {};
}

let sessionQuery: UseQueryResult<AvailabilitySession>;

function useAvailabilitySession(
  id: string,
  {
    activeMonth,
    activeDay,
    activeQuantity,
    allowCache = false,
    t2 = null,
    dateRange: [minDate, maxDate] = [
      moment(new Date()).utc().startOf('day'),
      moment(new Date()).add(365, 'days').utc().endOf('day'),
    ],
    handleError,
  }: AvailabilitySessionOptions
): {
  query: UseQueryResult<AvailabilitySession, unknown>;
  availabilitySession: AvailabilitySession | null;
} {
  const staleTime = 1000 * 60 * 11; // 11 min
  const queryClient = useQueryClient();
  const { locale } = useContext(SettingsContext);

  const maxActiveMonth = moment.max(activeMonth, minDate);

  const monthStart = activeMonth.clone().startOf('month');
  const startDate = moment
    .max(minDate, monthStart.clone().subtract(1, 'M'))
    .startOf('day');
  const endDate = moment
    .min(maxDate, monthStart.clone().add(1, 'M'))
    .endOf('day');

  sessionQuery = useQuery<AvailabilitySession>(
    [
      'availability/session',
      {
        session_id: id,
        t1: startDate.utc().format(dateFormat),
        t2: t2 || endDate.utc().format(dateFormat),
        allowCache,
      },
    ],
    async () => {
      const { data } = await tktApi.get('availability/session', {
        params: {
          session_id: id,
          allow_cache: allowCache,
          t1: startDate.utc().format(dateFormat),
          t2: t2 || endDate.utc().format(dateFormat),
        },
        headers: {
          'accept-language': locale || 'en',
        },
      });

      return data;
    },
    {
      enabled:
        !!id &&
        maxActiveMonth.isSameOrAfter(minDate) &&
        maxActiveMonth.isSameOrBefore(maxDate),
      staleTime,
      keepPreviousData: true,
      retry: (_failureCount, error) => {
        const statusCode = (error as AxiosError).response?.status;

        if (statusCode === 502 || statusCode === 503 || statusCode === 504) {
          return true;
        }

        return false;
      },
      onError: () => {
        if (handleError) {
          handleError();
        }
      },
    }
  );

  // Lazy day availability query
  useQuery<DayAvailability>(
    [
      'availability/day',
      {
        session_id: id,
        quantity: activeQuantity,
        day: activeDay?.format(dateFormat),
        t1: startDate.utc().format(dateFormat),
        t2: endDate.utc().format(dateFormat),
      },
    ],
    async () => {
      const { data } = await tktApi.get('availability/day', {
        params: {
          session_id: id,
          quantity: activeQuantity,
          day: activeDay?.format(dateFormat),
        },
        headers: {
          'accept-language': locale || 'en',
        },
      });

      return data;
    },
    {
      enabled:
        !!id &&
        !!activeDay &&
        activeQuantity !== undefined &&
        sessionQuery.isFetched,
      staleTime,
    }
  );

  // Combine all session queries made thus far
  const cachedSessionQueries = getAllSessionQueries(queryClient, id);

  const slotMap: Record<string, AvailabilitySlot> = {};
  let session: AvailabilitySession | null = null;
  for (const cachedSession of cachedSessionQueries) {
    if (!session) {
      session = cachedSession;
    }
    for (const slot of cachedSession.availability) {
      const { id: slotId, ttl, latest } = slot;
      if (
        !slotMap[slotId] ||
        moment(slotMap[slotId].ttl).isBefore(ttl) ||
        (!slotMap[slotId].latest && latest)
      ) {
        slotMap[slotId] = slot;
      }
    }
  }

  // Apply all day availability queries made thus far
  const cachedDayQueries = getAllDayQueries(queryClient, id);
  for (const { availability } of cachedDayQueries) {
    for (const slot of availability) {
      const { id: slotId, ttl, latest } = slot;
      if (
        !slotMap[slotId] ||
        moment(slotMap[slotId].ttl).isBefore(ttl) ||
        (!slotMap[slotId].latest && latest)
      ) {
        slotMap[slotId] = slot;
      }
    }
  }

  return session
    ? {
        query: sessionQuery,
        availabilitySession: {
          ...session,
          availability: Object.values(slotMap),
        },
      }
    : { query: sessionQuery, availabilitySession: null };
}

export interface AvailabilityFlowOptions {
  availability_id: string | undefined;
  product: AvailabilityFlowProduct | AvailProduct | null | undefined;
  booking_options: BookingOption[];
  product_options: OptionChoice[];
  sequential: boolean | undefined | null;
}

function useGetAvailabilityFlow(id: string) {
  const { locale } = useContext(SettingsContext);

  return useQuery(
    ['availability/flow', { method: 'GET', session_id: id }],
    async () => {
      const { data } = await tktApi.get('availability/flow', {
        params: {
          session_id: id,
        },
        headers: {
          'accept-language': locale || 'en',
        },
      });
      return data;
    },
    { enabled: !!id, staleTime: 1000 * 60 * 11 } // 11min
  );
}

function useAvailabilityFlow(
  id: string,
  back: boolean,
  {
    availability_id,
    product,
    booking_options,
    product_options,
    sequential,
  }: AvailabilityFlowOptions
) {
  const { locale } = useContext(SettingsContext);
  const staleTime = 1000 * 60 * 11;

  const updateQuery = useQuery<AvailabilityFlow>(
    [
      'availability/flow',
      {
        method: 'POST',
        session_id: id,
        availability_id,
        product_id: product?.id,
        booking_options,
        product_options,
        sequential,
        back,
      },
    ],
    async () => {
      let display_data: NiceTicketData = {};
      function getNiceData() {
        const niceData: NiceTicketData = {};

        booking_options.forEach((option) => {
          const { quantity, concession_id: concessionId } = option;
          const productConcessions = product?.concessions;
          const selectedConcession = productConcessions?.find(
            (concession) => concession.id === concessionId
          );

          if (selectedConcession && quantity) {
            if (niceData.concessions) {
              niceData.concessions.push({
                name: selectedConcession?.name,
                quantity: quantity.toString(),
              });
            } else {
              niceData.concessions = [
                {
                  name: selectedConcession?.name,
                  quantity: quantity.toString(),
                },
              ];
            }

            if (niceData.ticket_quantity) {
              niceData.ticket_quantity += quantity;
            } else {
              niceData.ticket_quantity = quantity;
            }

            niceData.ticket_type = product?.name;
          }
        });

        return niceData;
      }
      if (booking_options && Object.keys(booking_options).length > 0) {
        display_data = getNiceData();
      }

      const { data } = await tktApi.post(
        'availability/flow',
        {
          availability_id,
          product_id: product?.id,
          booking_options,
          product_options,
          display_data,
        },
        {
          params: {
            session_id: id,
            back,
          },
          headers: {
            'accept-language': locale || 'en',
          },
        }
      );
      return data;
    },
    {
      enabled:
        !!id &&
        !!sequential &&
        !!availability_id &&
        !!product &&
        !!sessionQuery &&
        sessionQuery.isFetched,
      staleTime,
      retry: (_failureCount, error) => {
        const statusCode = (error as AxiosError).response?.status;
        if (statusCode === 502 || statusCode === 503 || statusCode === 504) {
          return true;
        }

        return false;
      },
      cacheTime: 0,
    }
  );

  const getQuery = useQuery<AvailabilityFlow>(
    ['availability/flow', { method: 'GET', session_id: id }],
    async () => {
      const { data } = await tktApi.get('availability/flow', {
        params: {
          session_id: id,
        },
        headers: {
          'accept-language': locale || 'en',
        },
      });

      return data;
    },
    {
      enabled: !!id && !!sequential && updateQuery.isError,
      staleTime,
      cacheTime: 1000 * 30,
      retry: (_failureCount, error) => {
        const statusCode = (error as AxiosError).response?.status;
        if (statusCode === 502 || statusCode === 503 || statusCode === 504) {
          return true;
        }

        return false;
      },
    }
  );

  if (updateQuery.isError) {
    const err = updateQuery.error as AxiosError;

    return {
      query: getQuery,
      error: err.response?.data.detail[0] || true,
    };
  }

  return { query: updateQuery, error: null };
}

const client = {
  useGetAvailability,
  useAvailabilitySession,
  useAvailabilityFlow,
  useGetAvailabilityFlow,
};

export default client;
