import className from 'classnames';
import React, { useContext } from 'react';
import { AppViewContext } from '../../../contextProviders/AppViewProvider';
import { CareemContext } from '../../../contextProviders/CareemProvider';
import { ThemeContext } from '../../../contextProviders/ThemeProvider';
import Icon from '../../Icon';
import Text from '../Text';
import './style.scss';

export interface LoadingSpinnerProps {
  fullPage?: boolean;
  type?: 'simple' | 'fancy' | 'dots';
  text?: string;
}

export default function LoadingSpinner({
  fullPage = false,
  type = 'fancy',
  text,
}: LoadingSpinnerProps) {
  // TODO change this to the actual domain of Careem once live
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(AppViewContext);
  const { active: isCareem } = useContext(CareemContext);

  const tiketySplashDesktop =
    'https://storage.googleapis.com/tickitto_assets/Images/tikety-splash-desktop.png'.replace(
      'storage.googleapis.com/tickitto_assets',
      process.env.REACT_APP_IMAGE_ENGINE_URL as string
    );
  const tiketySplashMobile =
    'https://storage.googleapis.com/tickitto_assets/Images/tikety-splash-mobile.png'.replace(
      'storage.googleapis.com/tickitto_assets',
      process.env.REACT_APP_IMAGE_ENGINE_URL as string
    );

  const spinnerClass = className('loading-spinner__container', {
    'loading-spinner__container--fancy': type === 'fancy',
    'loading-spinner__container--simple': type === 'simple',
    'loading-spinner__container--dots': type === 'dots',
  });

  return (
    <div className="loading-spinner" data-testid="loading-spinner">
      {isCareem && fullPage ? (
        <div className="loading-spinner__splash">
          {isMobile ? (
            <img src={tiketySplashMobile} alt="splash screen" />
          ) : (
            <img src={tiketySplashDesktop} alt="splash screen" />
          )}
        </div>
      ) : (
        <div className={spinnerClass}>
          {type === 'fancy' && (
            <Icon id="syncCircle" color={theme['primary-color']} />
          )}
          {type === 'dots' && (
            <>
              <div className="dot-stretching" />
              <Text size="s">{text}</Text>
            </>
          )}
        </div>
      )}
    </div>
  );
}
