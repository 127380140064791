import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/ar';
import 'moment/locale/tr';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'url-search-params-polyfill'; // url search params polyfill
import LoadingSpinner from './components/Tiny/LoadingSpinner';
import './utils/i18n';

const App = React.lazy(() => import('./app'));

// INFO: Another way of using tracking
// const TrackedApp = track(
//   // app-level tracking data
//   { app: 'tickitto-webapp' },

//   {
//     // custom dispatch to console.log in addition to pushing to dataLayer[]
//     dispatch: (data: any) => {
//       console.log('DISPATCHED: ', data);
//       (window.dataLayer = window.dataLayer || []).push(data);
//     },
//   }
// )(App);

ReactDOM.render(
  <Suspense fallback={<LoadingSpinner fullPage />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
