/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import apis from '../apis';
import ErrorOverlay from '../components/ErrorOverlay';
import Icon from '../components/Icon';
import { AvailabilitySessionContext } from '../contextProviders/ApiAvailabilityProvider';
import { CareemContext } from '../contextProviders/CareemProvider';
import { ErrorReportingContext } from '../contextProviders/ErrorReportingProvider';
import { SettingsContext } from '../contextProviders/SettingsProvider';
import { ThemeContext } from '../contextProviders/ThemeProvider';
import { TicketCommsControllerContext } from '../contextProviders/TicketCommsController';
import { WidgetErrorUIControllerContext } from '../contextProviders/WidgetErrorUIController';
import ROUTES from '../constants/routes';
import {
  Basket,
  BasketAddData,
  BasketItem,
  Image as TImage,
} from '../definitions/tickitto';
import currencies from './currencies';
import {
  dispatchPostMessage,
  getNewUrl,
  getURLParameters,
  makePriceString,
} from './index';
import { TicketsContext } from '../contextProviders/TicketsProvider';

export function useCurrencyMultiplier(
  convertFrom: string | undefined,
  convertTo = 'GBP'
): {
  currencyMultiplier: number;
  currencyFetched: boolean;
  makePriceString: (price: number) => string;
  makePriceStringWithoutMultiplier: (price: number) => string;
  makePriceStringWithoutMultiplierWithDecimals: (price: number) => string;
  makePriceStringWithDecimals: (price: number) => string;
} {
  const [currencyMultiplier, setCurrencyMultiplier] = useState<number>(1);
  const { active: isCareem } = useContext(CareemContext);
  const { locale } = useContext(SettingsContext);
  const [currencyFetched, setCurrencyFetched] = useState(false);

  useEffect(() => {
    if (convertTo === 'JUN') {
      setCurrencyFetched(true);
    } else if (convertTo !== 'GBP' && currencyMultiplier === 1) {
      setCurrencyFetched(false);
    } else setCurrencyFetched(true);
  }, [currencyMultiplier]);

  if (convertFrom != null) {
    currencies.getMultiplier(convertFrom, convertTo, setCurrencyMultiplier);
  }

  return {
    currencyMultiplier: convertTo === 'JUN' ? 2 : currencyMultiplier,
    currencyFetched,
    makePriceString: (price: number) => {
      if (convertTo === 'JUN') {
        let junoPrice = price;
        if (price < 0.1) {
          junoPrice = 0;
        }
        return `${Math.ceil(junoPrice * 2)} JUNO`;
      }
      return makePriceString(
        price * currencyMultiplier,
        convertTo,
        locale,
        isCareem
      );
    },
    makePriceStringWithoutMultiplier: (price: number) => {
      if (convertTo === 'JUN') {
        let junoPrice = price;
        if (price < 0.1) {
          junoPrice = 0;
        }
        return `${Math.ceil(junoPrice * 2)} JUNO`;
      }
      return makePriceString(price, convertTo, locale, isCareem);
    },
    makePriceStringWithoutMultiplierWithDecimals: (price: number) => {
      return makePriceString(price, convertTo, locale, false);
    },
    makePriceStringWithDecimals: (price: number) => {
      return makePriceString(
        price * currencyMultiplier,
        convertTo,
        locale,
        false
      );
    },
  };
}

export function useGetSessionId() {
  const { session: sessionData } = useContext(AvailabilitySessionContext);
  if (sessionData == null) {
    const { session: sessionId } = getURLParameters();
    if (sessionId == null) {
      return null;
    }
    return sessionId;
  }
  return sessionData._id;
}

export function useSumArrayOfPrices(
  pricesArr: BasketItem[],
  currencyWanted: string
) {
  const [totPrices, setTotPrice] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const runAsync = async () => {
      let currVal = 0;
      for (let i = 0; i < pricesArr.length; i++) {
        const ticket = pricesArr[i];
        const price =
          Number(ticket.price.amount) + Number(ticket.booking_fee.amount);
        // TODO performance for this can be improved by using a promise all instead
        // eslint-disable-next-line no-await-in-loop
        const multiplier = await currencies.getMultiplier(
          ticket.price.currency,
          currencyWanted
        );

        currVal += price * multiplier;
      }
      if (isMounted) {
        setTotPrice(currVal);
      }
    };
    runAsync();

    return () => {
      isMounted = false;
    };
  }, [pricesArr]);

  return totPrices;
}

export function useCheckoutPricing(
  basket: Basket | undefined,
  currencyWanted: string
) {
  const tickets = basket?.items || [];
  const [totalWithoutFee, setTotalWithoutFee] = useState(0);
  const [totalWithFee, setTotalWithFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [finalPriceWithFee, setFinalPriceWithFee] = useState(0);
  const [finalPriceWithoutFee, setFinalPriceWithoutFee] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const runAsync = async () => {
      const multiplier = await currencies.getMultiplier('GBP', currencyWanted);

      const productsTotalWithoutFee = tickets.reduce(
        (prev, curr) =>
          prev +
          (Number(curr.price.amount) -
            (Number(Math.max(0, curr.supplier_adjustment.amount)) +
              Number(curr.booking_fee.amount))),
        0
      );

      const productTotalWithFee = tickets.reduce(
        (prev, curr) => prev + Number(curr.price.amount),
        0
      );

      const tempTotalFee = tickets.reduce(
        (prev, curr) =>
          prev +
          (Math.max(0, curr.supplier_adjustment.amount) +
            curr.booking_fee.amount),
        0
      );
      const tempTotalDiscount = basket?.total_discount?.amount;
      const tempFinalPriceWithFee = Math.max(
        0,
        productTotalWithFee - Math.max(0, tempTotalDiscount!)
      );
      const tempFinalPriceWithoutFee = Math.max(
        0,
        productsTotalWithoutFee - Math.max(0, tempTotalDiscount!)
      );

      if (isMounted) {
        setTotalWithoutFee(productsTotalWithoutFee * multiplier);
        setTotalWithFee(productTotalWithFee * multiplier);
        setTotalFee(tempTotalFee * multiplier);
        setTotalDiscount(
          tempTotalDiscount ? tempTotalDiscount * multiplier : 0
        );
        setFinalPriceWithFee(tempFinalPriceWithFee * multiplier);
        setFinalPriceWithoutFee(tempFinalPriceWithoutFee * multiplier);
      }
    };
    runAsync();
    return () => {
      isMounted = false;
    };
  }, [basket, tickets, currencyWanted]);

  return {
    totalWithoutFee,
    totalWithFee,
    totalFee,
    totalDiscount,
    finalPriceWithFee,
    finalPriceWithoutFee,
  };
}

// Total ticket prices  without where supplier_adjustments substracted
export function useSumArrayOfTickets(
  pricesArr: BasketItem[],
  currencyWanted: string
) {
  const [totPrices, setTotPrice] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const runAsync = async () => {
      let currVal = 0;
      for (let i = 0; i < pricesArr.length; i++) {
        const ticket = pricesArr[i];
        const price =
          Number(ticket.price.amount) -
          Number(Math.max(0, ticket.supplier_adjustment.amount));
        // TODO performance for this can be improved by using a promise all instead
        // eslint-disable-next-line no-await-in-loop
        const multiplier = await currencies.getMultiplier(
          ticket.price.currency,
          currencyWanted
        );

        currVal += price * multiplier;
      }

      if (isMounted) {
        setTotPrice(currVal);
      }
    };
    runAsync();

    return () => {
      isMounted = false;
    };
  }, [pricesArr]);

  return totPrices;
}

/* contains lots of common logic for adding tickets to the basket
  returns two things:
    A React element to display if there is an error
    A function to be run when the add to basket button is clicked, this function takes in: ids, ticketData, displayData and a callback
*/

export interface BookOptions {
  directBooking?: boolean;
  onSuccessRedirectToMyBasket?: boolean;
}

export function useAddTicketToBasket() {
  const { onAddToBasket } = useContext(TicketCommsControllerContext);
  const { theme } = useContext(ThemeContext);
  const { setErrorUi } = useContext(WidgetErrorUIControllerContext);
  const sessionId = useGetSessionId();
  const { throwError } = useContext(ErrorReportingContext);
  const basketItemsQuery = apis.tickitto.useAddItemToBasket();
  const [directBookingRequested, setDirectBookingRequested] = useState(false);
  const [redirectToMyBasket, setRedirectToMyBasket] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { actions: ticketActions } = useContext(TicketsContext);

  // handle success
  useEffect(() => {
    const asyncFunc = async () => {
      if (basketItemsQuery.isSuccess) {
        const { data } = basketItemsQuery;
        const newData = basketItemsQuery.data;

        for (let i = 0; i < data.items.length; i++) {
          const ticket = data.items[i];
          onAddToBasket(ticket);
          newData.items[i] = ticket;
          if (redirectToMyBasket) {
            ticketActions.add(ticket.id, ticket);
          }
        }

        dispatchPostMessage('NEW_TICKET_SELECTED', {
          basket_id: newData._id,
          items: newData.items,
          direct_booking: directBookingRequested,
        });

        if (redirectToMyBasket) {
          history.push({
            pathname: getNewUrl(ROUTES.SHOPPING_CART_PAGE, {}, false),
            state: { showBasketSuccessMessage: true },
          });
        }
      }
    };
    asyncFunc();
  }, [basketItemsQuery.isSuccess]);

  // handle fail
  useEffect(() => {
    // eslint-disable-next-line dot-notation
    if (basketItemsQuery.isError && basketItemsQuery.data?.['status'] !== 200) {
      let errorMessage = t('message.eventNotAvailable');

      const error = basketItemsQuery.error as {
        response?: { data?: { detail?: { msg?: string }[] } };
      };

      if (
        basketItemsQuery.error &&
        error.response?.data?.detail &&
        error.response?.data?.detail?.length > 0 &&
        error.response?.data?.detail[0]?.msg
      ) {
        errorMessage = error.response.data.detail[0].msg;
      }

      dispatchPostMessage('BOOKING_ERROR');
      throwError(`Could not book ticket with this session: ${sessionId}`);
      setErrorUi(
        <ErrorOverlay
          content={
            <div className="full-page-err">
              <Icon
                id="ticket"
                color={theme['primary-color']}
                showAlt={false}
              />
              <h1>{t('message.somethingWentWrong')}</h1>
              <span>{errorMessage}</span>
            </div>
          }
        />
      );
    }
  }, [basketItemsQuery.isError]);

  return {
    isLoading: basketItemsQuery.isLoading,
    isError: basketItemsQuery.isError,
    errorMessage: (
      basketItemsQuery.error as {
        response?: { data?: { detail?: { msg?: string }[] } };
      }
    )?.response?.data?.detail?.[0]?.msg,
    addToBasketFunc: (
      basketData: BasketAddData,
      {
        directBooking = false,
        onSuccessRedirectToMyBasket = false,
      }: BookOptions = {}
    ) => {
      basketItemsQuery.mutate({
        sessionId: sessionId!,
        basketData,
      });
      setRedirectToMyBasket(onSuccessRedirectToMyBasket);
      setDirectBookingRequested(directBooking);
    },
    bookingOptions: { directBooking: directBookingRequested },
  };
}

export interface RateLimitOptions {
  interval?: number;
  callLimit?: number;
}

/**
 * Can be used to limit the invocation count of a function within a given time period.
 *
 * e.g. when reacting to live user keyboard input to limit the number of requests or the amount of processing that could worsen the user experience.
 *
 * The boolean should be used to enable or disable the rate limited function, while the returned function should be called from within the rate limited function.
 *
 * Default interval is 500ms and default call limit is 1, meaning max 1 call per 500ms.
 */
export function useRateLimit({
  interval = 500,
  callLimit = 1,
}: RateLimitOptions): [boolean, () => void] {
  const [callsLeft, setCallsLeft] = useState(callLimit);

  useEffect(
    function startRateLimiter() {
      let handle: number;
      function resetRateLimiter() {
        if (callsLeft < callLimit) {
          setCallsLeft(callLimit);
        }
        handle = window.setTimeout(resetRateLimiter, interval);
      }
      handle = window.setTimeout(resetRateLimiter, interval);
      return function stopRateLimiter() {
        window.clearTimeout(handle);
      };
    },
    [interval, callLimit, callsLeft]
  );

  return [
    callsLeft > 0,
    function recordInvocation() {
      setCallsLeft(callsLeft - 1);
    },
  ];
}

export function useLazyImageLoading(src: string) {
  const [loadedImg, setLoadedImg] = useState<string | undefined>();

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.src = src;
      img.onload = () => setLoadedImg(src);
    } else {
      setLoadedImg(undefined);
    }
  }, [src]);

  return loadedImg;
}

export function getImageSrc(
  image: TImage | null,
  desktopOnly: boolean = false
): string | undefined {
  if (image == null) {
    return undefined;
  }

  if (image.mobile != null && !desktopOnly) {
    return image?.mobile?.replace(
      'storage.googleapis.com/tickitto_assets',
      process.env.REACT_APP_IMAGE_ENGINE_URL as string
    );
  }
  return image?.desktop?.replace(
    'storage.googleapis.com/tickitto_assets',
    process.env.REACT_APP_IMAGE_ENGINE_URL as string
  );
}

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// combination of setInterval and clearInterval with dynamic callback
export const useInterval = (callback: () => void, delay: number) => {
  const intervalRef = useRef<number>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(
        () => callbackRef.current(),
        delay
      );

      // Clear interval if the components is unmounted or the delay changes:
      return () => window.clearInterval(intervalRef.current);
    }
    return () => null;
  }, [delay]);

  return intervalRef;
};

// combination of setTimeout and clearTimeout with dynamic callback
export const useTimeout = (
  callback: () => void,
  delay: number,
  dependencies: any[] = []
) => {
  const timerRef = useRef<number>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay === 'number') {
      timerRef.current = window.setTimeout(() => callbackRef.current(), delay);

      // Clear timeout if the components is unmounted or the delay changes:
      return () => window.clearTimeout(timerRef.current);
    }

    return () => null;
  }, [delay, ...dependencies]);

  return timerRef;
};

// hook to inject script to the document body
export const useScript = (src: string, id: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = src;
    script.id = id;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};

// Use effect without ComponentDidMount side effect
export const useSkipFirstEffect = (callback: Function, dependencies: any[]) => {
  const firstRenderRef = useRef(true);

  const memoizedCallback = useCallback(() => callback(), [...dependencies]);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      memoizedCallback();
    }
  }, [memoizedCallback, ...dependencies]);
};
