import classNames from 'classnames';
import { TFunctionResult } from 'i18next';
import React from 'react';
import './style.scss';

export enum TextS {
  xxl = 'xxl',
  xl = 'xl',
  l = 'l',
  m = 'm',
  s = 's',
  xs = 'xs',
}

// export enum TextTypes {
//   primary = "primary",
//   secondary = "secondary",
// }

export type TextTypes = 'primary' | 'secondary';
export type TextWeight = 'bold' | 'italic' | 'normal';
export type TextColors =
  | 'highlighted'
  | 'dark'
  | 'light'
  | 'normal'
  | 'inverted';
export interface TextProps {
  size: `${TextS}`;
  type?: TextTypes;
  children:
    | string
    | TFunctionResult
    | (boolean | React.ReactChild | React.ReactFragment | React.ReactPortal);
  color?: TextColors;
  hexColor?: string;
  weight?: TextWeight;
  unselectable?: boolean;
  className?: string;
}

const Text: React.FC<TextProps> = ({
  children,
  size,
  type = 'primary',
  color = 'normal',
  weight = 'normal',
  hexColor,
  unselectable,
  className,
}) => {
  const mainClass = classNames(
    'text',
    className,
    `text--${size}`,
    `text--${type}`,
    {
      [`text--highlightColor`]: color === 'highlighted',
      [`text--lightColor`]: color === 'light',
      [`text--darkColor`]: color === 'dark',
      [`text--invertedColor`]: color === 'inverted',
      [`text--bold`]: weight === 'bold',
      [`text--italic`]: weight === 'italic',
      'text--unselectable': unselectable,
    }
  );

  return (
    <span
      className={mainClass}
      style={hexColor != null ? { color: hexColor } : undefined}
    >
      {children}
    </span>
  );
};

export default Text;
