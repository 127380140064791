/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query/types/react/types';
import apis from '../apis/index';
import { setBasketId } from '../apis/tickitto/basket';
import {
  AvailabilityFlow,
  AvailabilityFlowProduct,
  AvailabilitySession,
  AvailabilitySlot,
  AvailProduct,
  BookingOption,
  OptionChoice,
} from '../definitions/tickitto';
import { getURLParameters } from '../utils/index';
import { dateFormat } from './EventsProvider';

export interface ApiAvailibilityContext {
  session: AvailabilitySession | null;
  sessionQuery: UseQueryResult<AvailabilitySession, unknown> | null;
  flowData: AvailabilityFlow | null;
  flowError: any | null;
  flowQuery: UseQueryResult<AvailabilityFlow, unknown> | null;
  activeMonth: moment.Moment;
  activeDay: moment.Moment | null;
  activeSlot: AvailabilitySlot | null;
  activeQuantity: number | null | undefined;
  sequential: boolean | null | undefined;
  product: AvailabilityFlowProduct | AvailProduct | null | undefined;
  back: boolean;
  setActiveMonth(month: moment.Moment): void;
  setActiveDay(day: moment.Moment | null): void;
  setActiveSlot(slot: AvailabilitySlot | null): void;
  setActiveQuantity(quantity: number | null): void;
  setSequential(sequential: boolean): void;
  setAvailabilityId(id: string): void;
  setProduct(
    prod: AvailabilityFlowProduct | AvailProduct | null | undefined
  ): void;
  setBookingOptions(options: BookingOption[]): void;
  setProductOptions(options: OptionChoice[]): void;
  setBack(back: boolean): void;
  isFetched: boolean;
}

export const AvailabilitySessionContext =
  React.createContext<ApiAvailibilityContext>({
    session: null,
    sessionQuery: null,
    flowData: null,
    flowError: null,
    flowQuery: null,
    isFetched: false,
    activeMonth: moment.parseZone(new Date()),
    activeDay: null,
    activeSlot: null,
    activeQuantity: null,
    sequential: null,
    product: null,
    back: false,
    setActiveMonth() {},
    setActiveDay() {},
    setActiveSlot() {},
    setActiveQuantity() {},
    setSequential() {},
    setAvailabilityId() {},
    setProduct() {},
    setBookingOptions() {},
    setProductOptions() {},
    setBack() {},
  });

export interface AvailabilitySessionProviderProps {
  sessionId?: string;
  isEventPage?: boolean;
  initialDate?: moment.Moment;
  initialSlot?: AvailabilitySlot;
  daySelected?: boolean;
}

export const AvailabilitySessionProvider: React.FC<AvailabilitySessionProviderProps> =
  React.memo(
    ({
      sessionId = getURLParameters().session,
      isEventPage = false,
      children,
      initialDate,
      initialSlot,
      daySelected = false,
    }) => {
      const [activeMonth, setActiveMonth] = useState(
        initialDate
          ? moment.max(initialDate, moment(new Date()))
          : moment.parseZone(new Date())
      );
      const [activeDay, setActiveDay] = useState(
        (daySelected && initialDate) || null
      );
      const [activeSlot, setActiveSlot] = useState<AvailabilitySlot | null>(
        initialSlot || null
      );
      const [activeQuantity, setActiveQuantity] = useState<
        number | null | undefined
      >(undefined);

      // sequential flow variables
      const [sequential, setSequential] = useState<boolean | undefined>();
      const [availabilityId, setAvailabilityId] = useState<string | undefined>(
        activeSlot && activeSlot.products && activeSlot?.products.length > 0
          ? activeSlot?.products[0].availability_id
          : undefined
      );

      const [product, setProduct] = useState<
        AvailabilityFlowProduct | AvailProduct | undefined | null
      >(
        activeSlot && activeSlot.products && activeSlot.products.length > 0
          ? activeSlot?.products[0]
          : undefined
      );

      useEffect(() => {
        if (
          activeSlot &&
          activeSlot.products &&
          activeSlot?.products.length > 0
        ) {
          if (availabilityId !== activeSlot?.products[0].availability_id) {
            setAvailabilityId(activeSlot?.products[0].availability_id);
          }
          if (product !== activeSlot?.products[0]) {
            setProduct(activeSlot?.products[0]);
          }
        }
      }, [activeSlot]);

      const [productOptions, setProductOptions] = useState<OptionChoice[]>([]);
      const [bookingOptions, setBookingOptions] = useState<BookingOption[]>([
        {},
      ]);
      const [back, setBack] = useState<boolean>(false);

      // if the back button is shown it means we are on the event page
      const { query: availabilitySessionQuery, availabilitySession } =
        apis.tickitto.useAvailabilitySession(sessionId || '', {
          activeMonth,
          activeDay,
          activeQuantity,
          allowCache: isEventPage && !activeDay,
        });

      // Update active slot
      if (activeSlot && !activeSlot.latest && availabilitySession) {
        const latestSlot = availabilitySession.availability.find(
          (a) => a.latest && a.id === activeSlot.id
        );
        if (latestSlot) {
          setActiveSlot(latestSlot);
        }
      }

      const {
        query: availabilityFlowQuery,
        error: availabilityFlowQueryError,
      } = apis.tickitto.useAvailabilityFlow(sessionId || '', back, {
        sequential,
        availability_id: availabilityId,
        product,
        product_options: productOptions,
        booking_options: bookingOptions,
      });

      if (availabilitySession) {
        setBasketId(availabilitySession.basket_id);

        const isSeated = availabilitySession.availability.some(
          (slot) => slot.seatplan_required
        );

        if (!isSeated && activeQuantity === undefined) {
          // To fetch day availability beforehand and showing concession page
          setActiveQuantity(null);
        }

        // If a slot is already selected and day availability is fetched afterwards, update the selected slot
        if (
          activeSlot &&
          activeSlot.products.length === 0 &&
          availabilitySession.availability.find(
            (slot) => slot.id === activeSlot.id
          )?.products.length! > 0
        ) {
          const newActiveSlot = availabilitySession.availability.find(
            (slot) => slot.id === activeSlot.id
          );
          if (newActiveSlot) {
            setActiveSlot(newActiveSlot);
          }
        }
      }

      return (
        <AvailabilitySessionContext.Provider
          value={{
            isFetched:
              (availabilitySessionQuery.isFetched ||
                availabilitySessionQuery.isStale) &&
              (sequential
                ? availabilityFlowQuery.isFetched &&
                  !availabilityFlowQuery.isFetching
                : true) &&
              (activeSlot == null ||
                activeQuantity === undefined ||
                activeSlot.latest),
            session: availabilitySession,
            sessionQuery: availabilitySessionQuery,
            flowData: availabilityFlowQuery.data || null,
            flowError: availabilityFlowQueryError || null,
            flowQuery: availabilityFlowQuery,
            activeMonth,
            activeDay,
            activeSlot,
            activeQuantity,
            sequential,
            product,
            back,
            setActiveMonth(newMonth) {
              if (
                newMonth.format(dateFormat) !== activeMonth?.format(dateFormat)
              ) {
                setActiveMonth(newMonth);
              }
            },
            setActiveDay(newDay) {
              if (activeDay === newDay) {
                return;
              }
              if (newDay && activeDay && !newDay.diff(activeDay)) {
                return;
              }
              setActiveDay(newDay);
            },
            setActiveSlot(newSlot) {
              if (newSlot === activeSlot) {
                return;
              }
              setActiveSlot(newSlot);
            },
            setActiveQuantity(newQuantity) {
              if (activeQuantity === newQuantity) {
                return;
              }
              setActiveQuantity(newQuantity);
            },
            setSequential(newSequential) {
              if (sequential === newSequential) {
                return;
              }
              setSequential(newSequential);
            },
            setAvailabilityId(newAvailabilityId) {
              if (availabilityId === newAvailabilityId) {
                return;
              }
              setAvailabilityId(newAvailabilityId);
            },
            setProduct(newProduct) {
              setBack(false);
              if (product === newProduct) {
                return;
              }
              setProduct(newProduct);
            },
            setBookingOptions(newBookingOptions) {
              setBack(false);
              if (bookingOptions === newBookingOptions) {
                return;
              }
              setBookingOptions(newBookingOptions);
            },
            setProductOptions(newProductOptions) {
              setBack(false);
              if (productOptions === newProductOptions) {
                return;
              }
              setProductOptions(newProductOptions);
            },
            setBack(newBack) {
              if (back === newBack) {
                return;
              }
              setBack(newBack);
            },
          }}
        >
          {children}
        </AvailabilitySessionContext.Provider>
      );
    }
  );

export default AvailabilitySessionProvider;
