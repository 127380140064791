import { useQuery } from 'react-query';
import { defaultStaleTime } from '../../constants/api';
import { renameCustomTheme } from '../../utils';
import tktApi from '../instances/tickitto';

function useGetProfile(selectors: { profileName?: string }) {
  return useQuery(
    ['/authentication/account/profile', { method: 'GET', params: selectors }],
    async () => {
      const { data } = await tktApi.get('authentication/account/profile', {
        params: {
          profile_name: selectors?.profileName,
        },
      });

      return data;
    },
    {
      select: renameCustomTheme,
      staleTime: defaultStaleTime,
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        const statusCode = String(error?.response?.status);
        if (statusCode.startsWith('4')) {
          return false;
        }
        if (failureCount < 3) {
          return true;
        }
        return false;
      },
    }
  );
}

const client = {
  useGetProfile,
};

export default client;
