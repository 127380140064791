import moment from 'moment';
import tktApi from '../apis/instances/tickitto';

interface RateCacheEntry {
  lastUpdate: moment.Moment;
  rates: Record<string, number>;
}

interface ApiRate {
  base: string;
  rate: number;
  last_updated: string;
}

interface ApiCurrenciesResponse {
  base_rates: ApiRate[];
}

class Currencies {
  protected cachedRates: Record<string, RateCacheEntry>;
  protected cacheTTLminutes: number;
  protected pendingCalls: any[];

  constructor() {
    this.cachedRates = {};
    this.cacheTTLminutes = 5;
    this.pendingCalls = [];
  }

  protected async getCachedRates(from: string) {
    try {
      // update the cahe in this case
      if (this.pendingCalls.includes(from)) {
        // wait for call to be done
        while (this.pendingCalls.includes(from)) {
          // eslint-disable-next-line  no-await-in-loop
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }

      if (
        this.cachedRates[from] == null ||
        this.cachedRates[from].lastUpdate.diff(moment(), 'minutes') >
          this.cacheTTLminutes
      ) {
        // add to pending calls
        this.pendingCalls.push(from);

        const {
          data: { base_rates: rates },
        } = await tktApi.get<ApiCurrenciesResponse>('currencies/');

        const cachedRates = {};
        Object.keys(rates).forEach((key) => {
          const { rate } = rates[key] as ApiRate;
          cachedRates[key] = rate;
        });

        this.cachedRates[from] = {
          lastUpdate: moment(),
          rates: cachedRates,
        };

        // remove from pending calls
        const index = this.pendingCalls.indexOf(from);
        if (index > -1) {
          this.pendingCalls.splice(index, 1);
        }
      }
      return this.cachedRates[from].rates;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  public async getMultiplier(
    from: string,
    to: string,
    callback: (rate: number) => void = () => {}
  ) {
    try {
      const rates = (await this.getCachedRates(from)) as Record<string, number>;
      if (Object.keys(rates || {}).includes(to)) {
        callback(rates[to]);
        return rates[to];
      }
      callback(1);
      return 1;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export default new Currencies();
