import fetch from './fetch';
import locationIQ from './location-iq';
import tickitto from './tickitto';
import careem from './careem';
import availability from './tickitto/availability';

const apiClient = {
  fetch,
  locationIQ,
  tickitto: {
    ...tickitto,
    ...careem,
    ...availability,
  },
};

export default apiClient;
