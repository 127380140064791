/* eslint-disable import/prefer-default-export */

/** Splits a full name into [firstName, lastName]. */
export function splitName(name: string): [string, string] {
  const titles = ['Mr', 'Mrs', 'Ms', 'Miss', 'Dr'];
  titles.push(...titles.map((title) => title.toLowerCase()));
  const regExp = new RegExp(
    `^(?:(?:${titles.join(
      '|'
    )})\\.?\\s)?\\s*([^\\s]*)(?:(?:\\s+[^\\s]+)*(?:\\s+([^\\s]+)))?\\s*$`
  );
  const [, firstName = '', lastName = ''] = name.match(regExp) || [];
  return [firstName, lastName];
}
