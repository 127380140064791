/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { BasketItem } from '../definitions/tickitto';

export interface TicketCommsControllerContextType {
  isBackButtonShown?: boolean;
  onAddToBasket: (data: BasketItem) => void;
  onBackButtonPressed: (ev: React.MouseEvent<HTMLElement>) => void;
}

// this context is simply a utility to communicate with the widget deep in the component tree. Its a way to get event out of it or pass it some state it should use
export const TicketCommsControllerContext =
  React.createContext<TicketCommsControllerContextType>({
    onAddToBasket: () => {},
    onBackButtonPressed: () => {},
  });

export interface TicketCommsControllerProviderProps {
  isBackButtonShown?: boolean;
  onAddToBasket?: (data: BasketItem) => void;
  onBackButtonPressed?: (ev: React.MouseEvent<HTMLElement>) => void;
}

export function TicketCommsControllerProvider({
  children,
  isBackButtonShown,
  onAddToBasket = () => {},
  onBackButtonPressed = () => {},
}: React.PropsWithChildren<TicketCommsControllerProviderProps>) {
  return (
    <TicketCommsControllerContext.Provider
      value={{
        onAddToBasket,
        onBackButtonPressed,
        isBackButtonShown,
      }}
    >
      {children}
    </TicketCommsControllerContext.Provider>
  );
}
