import axios from 'axios';
import { getURLParameters } from '../../utils';

export const apiPrefix = `${process.env.REACT_APP_TICKITTO_CAREEM_API_ENDPOINT}`;

export function getTickittoCareemApiHeaders(): Record<string, string> {
  const key = process.env.REACT_APP_TIK_API_KEY;
  return key ? { 'X-CAREEM-APIKEY': key } : {};
}

const { profile } = getURLParameters();

const tktApi = axios.create({
  baseURL: apiPrefix,
  headers: getTickittoCareemApiHeaders(),
  params: { profile_name: profile },
});

export default tktApi;
