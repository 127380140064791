enum Routes {
  HOME_PAGE = '/',
  ABOUT_US = '/about-us', // TIKETY only
  NEWSLETTER_SIGNUP = '/newsletter-signup', // TIKETY only
  SEARCH_RESULTS = '/search-results',
  LOCATION_RESULTS = '/location-results',
  CATEGORY_RESULTS = '/category-results',
  EVENT_PAGE = '/events/:eventId',
  STANDALONE_PICKER = '/standalone-seats-picker',
  LOCATIONS_PAGE = '/locations',
  CATEGORIES_PAGE = '/categories',
  FAQ_PAGE = '/faqs-page',
  SHOPPING_CART_PAGE = '/shopping-cart-page',
  CHECKOUT_PAGE = '/checkout',
  CHECKOUT_SUCCESS_PAGE = '/checkout-success/:basketId',
  EVENT_TICKET_PICK_PAGE = '/ticket-pick',
  CINEMA_BOOK_PAGE = '/cinema-book',
  ORDERS = '/orders', // CAREEM only
  ORDER_PAGE = '/orders/:orderId/:basketId', // CAREEM only
  PRIVACY_POLICY = '/privacy-policy', // TIKETY only
}

export default Routes;
