import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ApiOrder, OrderItem } from '../../definitions/tickitto';
import { CareemContext } from '../../contextProviders/CareemProvider';
import { SettingsContext } from '../../contextProviders/SettingsProvider';
import careemTikApi from '../instances/tickitto-careem';
import { useGetBasketId } from '../tickitto/basket';

export function useGetOrders(
  type: 'future' | 'past',
  skip: number,
  limit: number
) {
  const queryClient: any = useQueryClient();
  const { locale } = useContext(SettingsContext);
  const { userDetails } = useContext(CareemContext);
  const { data: idData } = useGetBasketId();
  const basketId = idData?._id;

  return useQuery<ApiOrder>(
    [
      'orders/history/',
      'GET',
      type,
      skip,
      limit,
      userDetails?.id,
      locale,
      basketId,
    ],
    async () => {
      const { data } = await careemTikApi.get('orders/history', {
        headers: {
          'accept-language': locale || 'en',
        },
        params: {
          user_id: userDetails?.id,
          order_tense: type,
          skip,
          limit,
        },
      });

      return data;
    },
    {
      initialData: () => {
        // this basically checks the cached orders data to see if we already fetched
        const cachedOrdersData = queryClient.getQueryData({
          queryKey: [
            'orders/history/',
            'GET',
            type,
            skip,
            limit,
            userDetails?.id,
            locale,
            basketId,
          ],
          exact: false,
        });

        if (cachedOrdersData != null) {
          return cachedOrdersData;
        }

        return undefined;
      },
      staleTime: 1000 * 60 * 60, // 60min
      enabled: !!userDetails?.id && !!basketId,
    }
  );
}

export function useGetOrderById(basketId: string, itemId: string) {
  const queryClient: any = useQueryClient();
  const { locale } = useContext(SettingsContext);

  return useQuery<OrderItem>(
    ['orders/', 'GET', basketId, itemId, locale],
    async () => {
      const { data } = await careemTikApi.get('orders/', {
        headers: {
          'accept-language': locale || 'en',
        },
        params: {
          order_id: basketId,
          item_id: itemId,
        },
      });
      return data;
    },
    {
      initialData: () => {
        // this basically checks the cached orders data to see if we already fetched
        const cachedOrderData = queryClient.getQueryData({
          queryKey: ['orders/', 'GET', basketId, itemId, locale],
          exact: false,
        });

        if (cachedOrderData != null) {
          return cachedOrderData;
        }

        return undefined;
      },
      staleTime: 1000 * 60 * 60, // 60min
    }
  );
}

const client = {
  useGetOrders,
  useGetOrderById,
};

export default client;
