import React from 'react';
import './style.scss';
import classNames from 'classnames';

interface ColProps {
  className?: string;
  gutterSize?: 'm' | 's' | 'l' | 'none';
  justify?: 'center' | 'space-between' | 'start';
  fill?: boolean;
  testId?: string;
  role?: string;
}

const Col: React.FC<ColProps> = ({
  children,
  className = '',
  justify,
  gutterSize = 'none',
  fill = false,
  testId,
  role,
}) => (
  <div
    data-testid={testId}
    className={classNames('col', 'section-block', className, {
      [`col--justify-${justify}`]: justify != null,
      [`col--gutter-${gutterSize}`]: gutterSize !== 'none',
      'col--fill': fill,
    })}
    role={role}
  >
    {children}
  </div>
);

export default Col;
