import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.REACT_APP_ENVIRONMENT === 'development',
    fallbackLng: 'en-GB',
    transSupportBasicHtmlNodes: true,
    interpolation: {
      escapeValue: false,
      format: (val, format, lng) => {
        if (format === 'uppercase') return val.toUpperCase();
        if (format === 'lowercase') return val.toLowerCase();
        if (format === 'capitalize') return val[0].toUpperCase() + val.slice(1);

        return val;
      },
    },
  });

export default i18n;
