import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../contextProviders/ThemeProvider';
import { getURLParameters } from '../../utils';
import Icon from '../Icon';
import { IconID } from '../Icon/data';
import LoadingSpinner from '../Tiny/LoadingSpinner';
import Text from '../Tiny/Text';
import './style.scss';

type ButtonTypes =
  | 'plain'
  | 'plain-highlighted'
  | 'primary'
  | 'icon'
  | 'default';

export interface ButtonProps {
  text?: string;
  children?: React.ReactNode;
  type?: ButtonTypes;
  isTextColorInverted?: boolean;
  textWeight?: 'normal' | 'italic' | 'bold';
  size?: 'xs' | 's' | 'm' | 'l';
  isWithAnimatedArrow?: boolean;
  isIconAfter?: boolean;
  isIconRelative?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isFillingWidth?: boolean;
  icon?: IconID | null;
  iconColor?: string;
  iconHoverColor?: string;
  handleOnClick?: React.MouseEventHandler<HTMLElement>;
  handleOnAuxClick?: React.MouseEventHandler<HTMLElement>;
  handleOnDisabledClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  testid?: string;
  htmlType?: 'button' | 'submit' | 'reset';
}

export function openInNewTab(url: string, externalLink = false) {
  if (url && url !== '') {
    const { newTab } = getURLParameters();

    if (newTab || externalLink) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      const newTabUrl = url.includes('?')
        ? `${url}&new_tab=true`
        : `${url}?new_tab=true`;
      const newWindow = window.open(newTabUrl, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  } else {
    const newWindow = window.open(
      `${window.location.origin}?new_tab=true`,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  }
}

function useGetIconColor(
  buttonType: ButtonTypes,
  isHovering: boolean,
  iconColor?: string,
  iconHoverColor?: string
): string {
  const { theme } = useContext(ThemeContext);
  let iconColorPreset: string;

  switch (buttonType) {
    case 'primary':
      iconColorPreset = theme['text-color-inverted'];
      break;
    case 'default':
      iconColorPreset = theme['primary-color'];
      break;
    default:
      iconColorPreset = theme['text-color-default'];
  }

  const currIconColor =
    isHovering && iconHoverColor != null
      ? iconHoverColor
      : iconColor || iconColorPreset;

  return currIconColor;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  isTextColorInverted = false,
  textWeight = 'bold',
  type = 'primary',
  icon = null,
  iconColor,
  iconHoverColor,
  handleOnClick = () => {},
  handleOnAuxClick = () => {},
  handleOnDisabledClick = () => {},
  className,
  testid = '',
  htmlType = 'button',
  isDisabled = false,
  size = 'm',
  isLoading = false,
  isFillingWidth = false,
  isIconAfter = false,
  isIconRelative = false,
  isWithAnimatedArrow = false,
  children,
}: ButtonProps) => {
  const [isHovering, setHover] = useState(false);
  const btnClass = classNames(
    'new-button',
    className,
    `new-button--size-${size}`,
    `new-button--${type}`,
    {
      'new-button--isDisabled': isDisabled,
      'new-button--isHovering': isHovering,
      'new-button--isLoading': isLoading,
      'new-button--icon-after': isIconAfter,
      'new-button--fill-width': isFillingWidth,
      'new-button--fill-width--only': isFillingWidth && isIconRelative,
      'new-button--with-arrow-before': isWithAnimatedArrow && !isIconAfter,
      'new-button--with-arrow-after': isWithAnimatedArrow && isIconAfter,
      'new-button--with-arrow-after-relative':
        isWithAnimatedArrow && isIconAfter && isIconRelative,
    }
  );

  const textColorType =
    type === 'primary'
      ? 'inverted'
      : type === 'default' || type === 'plain-highlighted'
      ? 'highlighted'
      : type === 'plain'
      ? 'dark'
      : 'normal';

  const currTextColor = isTextColorInverted ? 'inverted' : textColorType;

  const currIconColor = useGetIconColor(
    type,
    isHovering,
    iconColor,
    iconHoverColor
  );

  return (
    <button
      onMouseEnter={() => {
        if (iconHoverColor != null && !isDisabled) {
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        if (iconHoverColor != null && !isDisabled) {
          setHover(false);
        }
      }}
      onClick={(e) => {
        if (!isDisabled) {
          handleOnClick(e);
          setHover(false);
        } else {
          handleOnDisabledClick(e);
        }
      }}
      onAuxClick={(e) => {
        if (!isDisabled) {
          e.stopPropagation();
          handleOnAuxClick(e);
          setHover(false);
        } else {
          handleOnDisabledClick(e);
        }
      }}
      className={btnClass}
      data-testid={testid}
      type={htmlType} // eslint-disable-line react/button-has-type
    >
      {isLoading ? <LoadingSpinner type="simple" /> : null}
      {isWithAnimatedArrow && !isIconAfter && (
        <Icon
          showAlt={false}
          size={size}
          id="rightAngleWhite"
          iconStyle="button-icon"
          color={isHovering ? iconHoverColor : currIconColor}
        />
      )}
      {icon && !isIconAfter && (
        <Icon
          showAlt={false}
          size={size}
          id={icon}
          iconStyle="button-icon"
          color={isHovering ? iconHoverColor : currIconColor}
        />
      )}
      {(text || children) && (
        <Text
          size={size}
          color={currTextColor}
          weight={textWeight}
          type={type === 'plain-highlighted' ? 'secondary' : 'primary'}
        >
          {text!}
          {children!}
        </Text>
      )}
      {icon && isIconAfter && (
        <Icon
          showAlt={false}
          size={size}
          id={icon}
          iconStyle="button-icon"
          color={isHovering ? iconHoverColor : currIconColor}
        />
      )}
      {isWithAnimatedArrow && isIconAfter && (
        <Icon
          showAlt={false}
          size={size}
          id="rightAngleWhite"
          iconStyle="button-icon"
          color={isHovering ? iconHoverColor : currIconColor}
        />
      )}
    </button>
  );
};

export default Button;
