import axios from 'axios';
import { getURLParameters } from '../../utils';

export const apiPrefix = `${process.env.REACT_APP_TIK_API_ENDPOINT}api/`;

export function getTickittoApiHeaders(): Record<string, string> {
  const key = process.env.REACT_APP_TIK_API_KEY;
  return key ? { key } : {};
}

const { profile } = getURLParameters();
const isCareem = window.location.hostname.includes('careem.tickitto');

const tktApi = axios.create({
  baseURL: apiPrefix,
  headers: getTickittoApiHeaders(),
  params: { profile_name: isCareem ? 'careem_tikety' : profile },
});

export default tktApi;
