/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useReducer, useContext } from 'react';
import moment from 'moment';
import logger from './utils';
import {
  Category,
  Event,
  EventSearchParams,
  Image,
} from '../definitions/tickitto';
import { useMetadata } from '../apis/tickitto';
import apis from '../apis';
import { ThemeContext } from './ThemeProvider';
import { getURLParameters } from '../utils';

export type EventsContext = [
  EventsState,
  boolean,
  { defaultFromDate: string; defaultToDate: string },
  (params: EventSearchParams, shouldClearEvents?: boolean) => void
];

export const dateFormat = 'YYYY-MM-DD';

export const EventsContext = React.createContext<EventsContext>([
  { events: [], locations: [], categories: [] },
  false,
  {
    defaultFromDate: moment().format(dateFormat),
    defaultToDate: moment().add('2', 'weeks').format(dateFormat),
  },
  () => {},
]);

export interface Location {
  city: string;
  country: string;
  country_code: string;
  longitude: number;
  latitude: number;
  images: ReadonlyArray<Image>;
  event_count: number;
  group_name?: string;
  count?: number;
}

export interface EventsState {
  events: Event[];
  locations: Location[];
  categories: Category[];
}

interface BaseAction<TType extends string, TPayload> {
  type: TType;
  payload: TPayload;
}

type AddEventsAction = BaseAction<'ADD_EVENTS', Event[]>;
type AddLocationsAction = BaseAction<'ADD_LOCATIONS', Location[]>;
type AddCategoriesAction = BaseAction<'ADD_CATEGORIES', Category[]>;

type Action = AddEventsAction | AddLocationsAction | AddCategoriesAction;

const initialState: EventsState = {
  events: [],
  locations: [],
  categories: [],
};

function reducer(state: EventsState, action: Action) {
  switch (action.type) {
    case 'ADD_EVENTS':
      return { ...state, events: action.payload };
    case 'ADD_LOCATIONS':
      return { ...state, locations: action.payload };
    case 'ADD_CATEGORIES':
      return { ...state, categories: action.payload };
    default:
      throw new Error('unexpected action');
  }
}

export function EventsProvider({ children }: React.PropsWithChildren<{}>) {
  const [eventData, dispatch] = useReducer(
    logger(reducer, 'EventsProvider'),
    initialState
  );

  const { customisations } = useContext(ThemeContext);

  const fromDate = moment().locale('en').format(dateFormat);
  let toDate = moment().locale('en').add('2', 'weeks').format(dateFormat);

  if (
    customisations &&
    customisations.date_range &&
    customisations.date_range.unit &&
    customisations.date_range.value
  ) {
    const { value, unit } = customisations.date_range;

    toDate = moment().locale('en').add(value, unit).format(dateFormat);
  }

  const [searchParams, setSearchParameters] = useState<EventSearchParams>({
    t1: fromDate,
    t2: toDate,
    profile: getURLParameters().profile || '',
    promocode: getURLParameters().promocode || '',
    currency: getURLParameters().currency || '',
  });

  // this is essentialy just a middleware to clear the events as soon as we ask for a new update
  function updateSearchParams(
    params: EventSearchParams,
    shouldClearEvents = false
  ) {
    if (shouldClearEvents) {
      dispatch({ type: 'ADD_EVENTS', payload: [] });
    }

    setSearchParameters(params);
  }

  const { isFetched: eventsFetched, data: eventsData } =
    apis.tickitto.useGetEventsData(searchParams);

  useEffect(() => {
    if (eventsFetched === true && eventsData != null) {
      dispatch({ type: 'ADD_EVENTS', payload: eventsData.events });
    }
  }, [eventsFetched, eventsData?.events]);

  // get locations and categories data
  const { isFetched, data: metadata } = useMetadata();
  useEffect(() => {
    if (isFetched && metadata != null) {
      if (metadata.categories.length) {
        dispatch({
          type: 'ADD_CATEGORIES',
          payload: metadata!.categories as Category[],
        });
      }

      const reformattedLocations = metadata.locations
        .map((l) =>
          l.cities.map<Location>((c) => ({
            city: c.name,
            country: l.country_name,
            country_code: l.country_code,
            latitude: c.latitude,
            longitude: c.longitude,
            images: c.images,
            event_count: l.event_count,
            group_name: c.group_name,
          }))
        )
        .flat();
      const newLocations = reformattedLocations.filter(
        (l) =>
          !eventData.locations.some(
            (otherL) =>
              otherL.city === l.city && otherL.country_code === l.country_code
          )
      );
      if (newLocations.length) {
        dispatch({
          type: 'ADD_LOCATIONS',
          payload: reformattedLocations,
        });
      }
    }
  }, [isFetched, metadata]);

  return (
    <EventsContext.Provider
      value={[
        eventData,
        isFetched,
        { defaultFromDate: fromDate, defaultToDate: toDate },
        updateSearchParams,
      ]}
    >
      {children}
    </EventsContext.Provider>
  );
}
