import classNames from 'classnames';
import React, { useContext } from 'react';
import SVGInliner from 'react-inlinesvg';
import LazyLoad from 'react-lazyload';
import { ThemeContext } from '../../contextProviders/ThemeProvider';
import { uuid } from '../../utils/index';
import { IconID } from './data';
import './style.scss';

const svgFormatter = (
  svgCode: string,
  color: string,
  keepOriginal: boolean
) => {
  let newCode = svgCode;
  if (!keepOriginal) {
    newCode = svgCode
      .replace(/fill:[^transparent].*?;/g, `fill:${color}`) // replace the color set in an embedded stylesheet, but ignore when it contains transparent
      .replace(/fill=["'][^transparent].*?["']/g, `fill="${color}"`); // replace the color set inline in the svg
  }
  // uniqiify all of the classes of thesvg emebedded stylesheet
  let uniqClassesCode = `${newCode}`;
  const allClasses = `${newCode}`.match(/class="[^"]*/g);
  const uniqueClasses = [...new Set(allClasses)];

  uniqueClasses.forEach((elem) => {
    const uniqeName = `svgClass${uuid()}`;
    const className = elem.substring(elem.indexOf('"') + 1, elem.length);
    if (newCode.includes(`.${className}{`)) {
      uniqClassesCode = uniqClassesCode
        .replace(new RegExp(`.${className}{`, 'g'), `.${uniqeName}{`)
        .replace(
          new RegExp(`class="${className}"`, 'g'),
          `class="${uniqeName}"`
        );
    }
  });

  return uniqClassesCode;
};

export interface IconProps {
  id: IconID;
  iconStyle?: string;
  isIconRelative?: boolean;
  color?: string | null;
  keepOriginal?: boolean;
  size?: 'xs' | 's' | 'm' | 'l';
  isPreloading?: boolean;
  alt?: string;
  showAlt?: boolean;
  styleClass?: string;
}

export default function Icon({
  id,
  iconStyle = 'icon',
  isIconRelative = false,
  color = null,
  keepOriginal = false,
  size,
  isPreloading = false,
  alt,
  styleClass = '',
  showAlt = true,
}: IconProps) {
  const { icons } = useContext(ThemeContext);

  const { src, alt: iconAlt } = icons[id];

  const isSvg = src.includes('.svg');

  const mainClass = classNames(iconStyle, styleClass, {
    [`icon--size-${size}`]: size != null,
    [`icon--isPreloading`]: isPreloading,
    [`icon--isRelative`]: isIconRelative,
  });

  if (!isSvg) {
    return (
      <LazyLoad>
        <img
          className={mainClass}
          src={src}
          alt={showAlt ? alt || iconAlt : undefined}
        />
      </LazyLoad>
    );
  }

  return (
    <SVGInliner
      data-testid={`svg-${id}`}
      key={id + iconStyle + color}
      className={mainClass}
      src={src}
      title={showAlt ? alt || iconAlt : undefined}
      cacheRequests
      uniquifyIDs
      loader={<svg className={iconStyle} />}
      onError={(e) => {
        console.error(`svg-inliner ${id} error:`, e);
      }}
      preProcessor={(code) => svgFormatter(code, color as string, keepOriginal)}
    />
  );
}
