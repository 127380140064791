import { getDistance } from 'geolib';
import moment from 'moment';
import ROUTES from '../constants/routes';
import { Location } from '../contextProviders/EventsProvider';
import { FilterData } from '../contextProviders/PopupsProvider';
import {
  AdmissionType,
  ApiPrice,
  AvailabilitySlot,
  BasketItemAdjustment,
  Event,
  EventSearchParams,
  Profile,
  Seat,
} from '../definitions/tickitto';

export function removeNullValsFromObject<T extends Record<any, any>>(
  obj: T
): { [P in keyof T]: Exclude<T[P], null | undefined> } {
  const cleanObj: Record<any, any> = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] != null) {
      cleanObj[key] = obj[key];
    }
  });

  return cleanObj as any;
}

export interface PossibleUrlConfigs {
  session?: string;
  profile?: string;
  locale?: string;
  currency?: string;
  checkTTL?: string;
  referrer?: string;
  fromDate?: string;
  toDate?: string;
  category?: string;
  basketId?: string;
  city?: string;
  country_code?: string;
  text?: string;
  sort_by?: string; // "price_asc" | "price_desc"
  event_name?: string;
  event_id?: string;
  promocode?: string;
  hideExtraPrice?: string;
  newTab?: string;
}

// get some url parameters from the current iframe url
export function getURLParameters(): PossibleUrlConfigs {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const paramsObj: any = {};
  paramsObj.session = params.get('session_id')!;
  paramsObj.profile = params.get('profile')!;
  paramsObj.locale = params.get('locale')!;
  paramsObj.currency = params.get('currency')!;
  paramsObj.checkTTL = params.get('check_ttl')!;
  paramsObj.referrer = params.get('referrer')!;
  paramsObj.fromDate = params.get('from_date')! || params.get('t1')!;
  paramsObj.toDate = params.get('to_date')! || params.get('t2')!;
  paramsObj.category = params.get('category')!;
  paramsObj.basketId = params.get('basket_id')!;
  paramsObj.city = params.get('city')!;
  paramsObj.country_code = params.get('country_code')!;
  paramsObj.text = params.get('text')!;
  paramsObj.event_name = params.get('event_name')!;
  paramsObj.event_id = params.get('event_id')!;
  paramsObj.promocode = params.get('promocode')!;
  paramsObj.hideExtraPrice = params.get('hide_extra_price')!;
  paramsObj.newTab = params.get('new_tab')!;
  // remove null vals
  return removeNullValsFromObject(paramsObj);
}

export function urlSearchParamsToObject(params: URLSearchParams) {
  const object = {} as Record<string, any>;
  for (const [key, value] of params.entries()) {
    if (object[key]) {
      if (!Array.isArray(object[key])) {
        object[key] = [object[key]];
      }
      object[key] = [...object[key], value];
    } else {
      object[key] = value;
    }
  }

  return object;
}

function reformatSearchParams(
  searchParams: EventSearchParams
): PossibleUrlConfigs {
  const retUrl: PossibleUrlConfigs = {};
  type EventSearchKeys = Extract<keyof EventSearchParams, string>;

  function keyRenaming(key: EventSearchKeys): keyof PossibleUrlConfigs {
    switch (key) {
      case 't1':
        return 'fromDate';
      case 't2':
        return 'toDate';
      default:
        return key;
    }
  }

  Object.keys(searchParams).forEach((paramKey) => {
    const paramVal = searchParams[paramKey as keyof EventSearchParams];
    if (Array.isArray(paramVal)) {
      retUrl[keyRenaming(paramKey as keyof EventSearchParams)] =
        paramVal.join(';');
    } else if (!Array.isArray(paramVal)) {
      retUrl[keyRenaming(paramKey as keyof EventSearchParams)] = paramVal;
    }
  });

  return retUrl;
}

export function getNewUrl(
  pathName: string = window.location.pathname,
  searchParams: EventSearchParams,
  shouldMaintainParams: boolean = true
): string {
  const urlParams = reformatSearchParams(searchParams);
  const newUrlParams = shouldMaintainParams
    ? { ...getURLParameters(), ...urlParams }
    : urlParams;

  let newUrl = `${pathName}`;

  function getParamPrefix(addedFirstParam: boolean): '&' | '?' {
    return addedFirstParam ? '&' : '?';
  }

  let addedFirstParam = false;

  if (newUrlParams.fromDate != null && newUrlParams.fromDate !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}from_date=${
      newUrlParams.fromDate
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.toDate != null && newUrlParams.toDate !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}to_date=${
      newUrlParams.toDate
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.city != null && newUrlParams.city !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}city=${newUrlParams.city}`;
    addedFirstParam = true;
  }

  if (newUrlParams.country_code != null && newUrlParams.country_code !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}country_code=${
      newUrlParams.country_code
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.category != null && newUrlParams.category !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}category=${
      newUrlParams.category
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.text != null && newUrlParams.text !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}text=${newUrlParams.text}`;
    addedFirstParam = true;
  }

  if (newUrlParams.session != null && newUrlParams.session !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}session_id=${
      newUrlParams.session
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.profile != null && newUrlParams.profile !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}profile=${
      newUrlParams.profile
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.event_name != null && newUrlParams.event_name !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}event_name=${
      newUrlParams.event_name
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.event_id != null && newUrlParams.event_id !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}event_id=${
      newUrlParams.event_id
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.promocode != null && newUrlParams.promocode !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}promocode=${
      newUrlParams.promocode
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.currency != null && newUrlParams.currency !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}currency=${
      newUrlParams.currency
    }`;
    addedFirstParam = true;
  }

  if (newUrlParams.newTab != null && newUrlParams.newTab !== '') {
    newUrl += `${getParamPrefix(addedFirstParam)}new_tab=${
      newUrlParams.newTab
    }`;
    addedFirstParam = true;
  }

  if (
    newUrlParams.hideExtraPrice != null &&
    newUrlParams.hideExtraPrice !== ''
  ) {
    newUrl += `${getParamPrefix(addedFirstParam)}hide_extra_price=${
      newUrlParams.hideExtraPrice
    }`;
    addedFirstParam = true;
  }
  return newUrl;
}

// compare a UTC date string to the current UTC time
export function compareDateWithCurrentUTC(dateString: string): number {
  const now = new Date();
  const currentUTCTime = moment.utc(now.toUTCString());
  const dateToCompare = moment.utc(dateString); // .diff(new Date) //compare to right now
  return dateToCompare.diff(currentUTCTime, 'seconds'); // compare to right now
}

// create uuid
export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise, eqeqeq
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// localizes how the price should be displayed
export function makePriceString(
  price: number,
  currency: string = 'GBP',
  locale: string = 'en-GB',
  hideDecimals: boolean = false
) {
  const internatinalizedFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: hideDecimals ? 0 : 2,
    minimumFractionDigits: hideDecimals ? 0 : 2,
  }).format(price);

  return internatinalizedFormat;
}

type WindowPostmMessages =
  | 'BOOKING_ERROR'
  | 'NEW_HEIGHT'
  | 'INITIALIZED'
  | 'NEW_TICKET_SELECTED'
  | 'SESSION_EXPIRED';

export function dispatchPostMessage(
  type: WindowPostmMessages,
  messageData = {}
) {
  const referrer = getURLParameters().referrer || '*';

  window.parent.postMessage({ type, messageData }, referrer);

  window.postMessage({ type, messageData }, referrer);
}

// returns a moment object of the oldest ttl, aka the one that gives you the least amount of time
export function getOldestTTL(ttlsArray: Array<string>): moment.Moment {
  let shortestTTl = moment.utc(ttlsArray[0]);

  ttlsArray.forEach((ttl) => {
    if (moment(ttl).diff(shortestTTl, 'seconds') > 0) {
      shortestTTl = moment.utc(ttl);
    }
  });

  return shortestTTl;
}

// takes two strings in iso format
export function buildDate(
  fromDate: string,
  toDate: string,
  format = 'DD-MM-YY HH:mm',
  hideTime = false
): string {
  let date = null;

  if (fromDate === toDate) {
    date = moment(fromDate).utcOffset(fromDate).format(format);
  } else if (!moment(fromDate).isSame(toDate, 'day')) {
    date = `${moment(fromDate).utcOffset(fromDate).format(format)} - ${moment(
      toDate
    )
      .utcOffset(toDate)
      .format(format)}`;
  } else {
    date = hideTime
      ? moment(fromDate).utcOffset(fromDate).format(format)
      : `${moment(fromDate).utcOffset(fromDate).format(format)} - ${moment(
          toDate
        )
          .utcOffset(toDate)
          .format('HH:mm')}`;
  }

  return date;
}

// cross compatible way of getting max scroll pos
export function getMaxScroll() {
  return Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
}

/*
  these are used to redirect the subdomain of widget.tickitto.tech
  to the standalone widget
*/
export function isWidgetMode() {
  return (
    window.location.href.includes('subdomain') ||
    window.location.href.includes('widget.') ||
    window.location.pathname.includes(ROUTES.STANDALONE_PICKER)
  );
}

// replacement for object.keys t typescript does not complain
function typedKeys<T>(o: T): (keyof T)[] {
  // type cast should be safe because that's what really Object.keys() does
  return Object.keys(o) as (keyof T)[];
}

// renames the variables from having an underscore to a "-"
export function renameCustomTheme(profile: Profile): Profile {
  const { theme, ...rest } = profile;
  const renamedTheme = profile.theme;
  typedKeys(theme).forEach((dictType) => {
    if (dictType === 'variables') {
      typedKeys(theme[dictType]).forEach((variableKey) => {
        renamedTheme[dictType][variableKey.replace(/_/g, '-')] =
          renamedTheme[dictType][variableKey];
        delete renamedTheme[dictType][variableKey];
      });
    }
  });

  return {
    theme: renamedTheme,
    ...rest,
  };
}
// typescript assert unrechable code
export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export function isArrayNotEmpty(arr: Array<any>): boolean {
  return arr != null && Array.isArray(arr) && arr.length > 0;
}

export interface NearbySearchOptions {
  kilometres?: number;
  limit?: number;
}

export function resolveNearbyLocations(
  location: Location,
  locations: ReadonlyArray<Location>,
  { kilometres = 50, limit = 3 }: NearbySearchOptions = {}
): ReadonlyArray<Location> {
  if (location.longitude == null || location.latitude == null) {
    console.warn(
      "can't use this location to resolve nearby locations as it does not have a longitude or a latitude"
    );
    return [];
  }

  const { latitude, longitude } = location;

  const distances = locations.map((thisLocation) => {
    if (thisLocation.latitude == null || thisLocation.longitude == null) {
      return { thisLocation, distance: NaN };
    }
    return {
      thisLocation,
      distance: getDistance(
        { latitude, longitude },
        { latitude: thisLocation.latitude, longitude: thisLocation.longitude }
      ),
    };
  });

  return distances
    .filter(
      ({ distance }) => !Number.isNaN(distance) && distance / 1000 <= kilometres
    )
    .sort((a, b) => a.distance - b.distance)
    .map(({ thisLocation }) => thisLocation)
    .slice(0, limit);
}

export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const date = new Date(startDate.getTime());
  date.setDate(date.getDate());
  const dates = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const getDayName = (date = new Date(), locale = 'en-US') => {
  return date.toLocaleDateString(locale, { weekday: 'short' });
};

export const getMonthName = (date = new Date(), locale = 'en-US') => {
  return date.toLocaleDateString(locale, { month: 'short' });
};

export const getUniqueFilterOptions = (
  filterProperty: string,
  options: AvailabilitySlot[]
) => {
  const uniqueResults = options
    .map((item) => item?.venue_location?.[filterProperty])
    .filter((result, index, self) => self.indexOf(result) === index);

  return uniqueResults?.map((vm) => {
    return { name: vm!, code: vm!, isSelected: false };
  });
};

export const groupAvailabilityByVenueAndScreen = (
  availabilities: AvailabilitySlot[]
): Record<string, Record<string, AvailabilitySlot[]>> => {
  // TODO: revisit when we rollout cinemas to non-AED events
  const withAllocatedSeating = availabilities.filter(
    (a) => a.seatplan_required
  );

  return withAllocatedSeating.reduce((acc, availability) => {
    const venueKey = `${availability?.venue_location?.id}:${availability?.venue_location?.venue_brand}:${availability?.venue_location?.venue_name}`;
    const screen = availability.venue_location?.configuration?.screenName;
    acc[venueKey] = acc[venueKey] || {};
    acc[venueKey][screen] = acc[venueKey][screen] || [];
    acc[venueKey][screen].push(availability);
    return acc;
  }, {});
};

export const getSelectedFilterOptions = (
  filterData: Record<string, FilterData>,
  property: string
) => {
  return filterData[property].options
    .filter((option) => option.isSelected)
    .map((f) => f.code);
};

export const getPartOfDay = (eventDate: string) => {
  const date = moment.parseZone(eventDate);
  const hour = date.hours();

  if (hour >= 9 && hour < 12) {
    return 'morning';
  }
  if (hour >= 12 && hour <= 18) {
    return 'afternoon';
  }
  if ((hour >= 18 && hour <= 23) || [0, 1, 2].includes(hour)) {
    return 'evening';
  }
  return '';
};

export const priceSum = (standardSeats: Seat[]) => {
  return standardSeats.reduce((acc, curr) => {
    if (!curr.price) {
      return acc;
    }
    return acc + curr.price;
  }, 0);
};

export const getLocation = (item: Event) => {
  if (item.city !== 'None' && item.country_code !== 'None') {
    return `${item.city}, ${item.country_code}`;
  }
  if (item.city !== 'None') {
    return item.city;
  }
  if (item.country_code !== 'None') {
    return item.country_code;
  }
  return '';
};

export const reduceAdjustments = (adjustments: BasketItemAdjustment[]) => {
  return adjustments.reduce((prev, curr) => {
    const exists = prev.find((x: any) => x.name === curr.name);
    if (exists) {
      exists.quantity++;
    } else {
      prev.push({
        name: curr.name,
        price: curr.price,
        quantity: 1,
      });
    }
    return prev;
  }, [] as { name: string; price: ApiPrice; quantity: number }[]);
};

export const shouldHideEventTime = (
  admissionType: AdmissionType | undefined
): boolean => {
  return (
    admissionType === AdmissionType.OPEN_ENTRY ||
    admissionType === AdmissionType.DATE_ENTRY
  );
};
