// Tickitto API definitions.
/* eslint-disable camelcase */
import { SeatPlanValidationRules } from '../components/SeatPlanView/ValidationLogic';

export enum AdmissionType {
  TIMED_ENTRY = 'timed_entry',
  DATE_ENTRY = 'date_entry',
  SLOT_ENTRY = 'slot_entry',
  OPEN_ENTRY = 'open_entry',
}

export enum BookingType {
  BASKET_BOOKING = 'basket_booking',
  DIRECT_BOOKING = 'direct_booking',
}

export enum SeatplanSource {
  TICKITTO = 'tickitto',
  ENCORE = 'encore',
  VIVIDSEATS = 'vividseats',
  SEATSIO = 'seatsio',
}

declare global {
  interface Window {
    less: any;
  }
}

export interface ApiImage {
  /**
   * URL to use on desktop devices
   */
  desktop: string;
  /**
   * URL to use on mobile devices
   */
  mobile?: string;
  /**
   * URL to use as thumbnail.
   */
  thumbnail?: string;
}

export interface ApiPrice {
  currency: string;
  amount: number;
}

export interface ApiDate {
  utc: string;
  local: string;
}

export interface Event {
  addon_required: boolean;
  addons: string[];
  admission_type: AdmissionType;
  booking_type: BookingType;
  cancellation_allowed: boolean;
  cancellation_policy: string;
  categories: string[];
  city: string;
  country: string;
  country_code: string;
  currency: string;
  descriptive_headline?: string;
  description: string;
  duration: any;
  covid19_notes: string[];
  entry_notes: string[];
  event_id: string;
  event_type?: 'Standard' | 'Cinema';
  from_price: ApiPrice;
  images: Array<ApiImage>;
  pickup_points: { [key: string]: any }[];
  pickup_required: boolean;
  product_highlights: string[];
  short_description: string;
  soft_availability_t1?: string;
  soft_availability_t2?: string;
  title: string;
  venue_location: VenueLocation[];
  product_includes: string[];
  product_excludes: string[];
  popularity: number;
  videos: string[];
}

export interface VenueLocation {
  id: string;
  venue_name: string;
  venue_city?: string;
  venue_brand?: string;
  venue_brand_id?: string;
  venue_country?: string;
  configuration?: { screenName: string };
  venue_address: string;
  latitude: number;
  longitude: number;
}

export interface EventSelection {
  eventID: string;
  perfID: string;
  blockID?: string;
}

export interface PromoCode {
  code: string;
  applicable: boolean;
  expired: boolean;
  discount: ApiPrice;
  description: string;
  title: string;
}

export interface Basket {
  _id: string;
  created_on: string;
  distributor_id: string;
  items: BasketItem[];
  total_booking_fee: ApiPrice;
  total_items_price: ApiPrice;
  total_price: ApiPrice;
  total_discount: ApiPrice;
  promocodes: PromoCode[];
  checkout_status: BasketStatus;
}

export interface NiceDataConcession {
  name: string;
  quantity: string;
}

// extra data the FR compiles to send to backend to enhance the ticke item data
export interface NiceTicketData {
  concessions?: Array<NiceDataConcession>;
  ticket_quantity?: number;
  ticket_type?: string;
}

/** INFO
  - unreserved: rereservation failed or hit max attempts and item will not attempt checkout
  - reserving: item is currently locked for pending re-reservation due to ttl expiry
  - reserved: item availability is locked and being held until checkout
  - saved: item availability is not locked and item will attempt to book at checkout
  - booking: item is currently locked for pending checkout due to /basket/checkout or Stripe webhook
  - booked: item booked successfully
  - errored: item booking error
  - cancelled: item cancelled
*/
export enum BasketItemStatus {
  UNRESERVED = 'unreserved',
  RESERVING = 'reserving',
  RESERVED = 'reserved',
  RESERVATION_PENDING = 'reservation_pending',
  FULFILMENT_PENDING = 'fulfilment_pending',
  FULFILMENT_ERRORED = 'fulfilment_errored',
  SAVED = 'saved',
  BOOKING = 'booking',
  BOOKED = 'booked',
  ERRORED = 'errored',
  CANCELLING = 'cancelling',
  CANCELLED = 'cancelled',
}

export enum BasketStatus {
  NOT_CHECKED_OUT = 'not_checked_out',
  CHECKOUT_PENDING = 'checkout_pending',
  PAYMENT_FAILED = 'payment_failed',
  CHECKED_OUT = 'checked_out',
  CHECKED_OUT_WITH_ERRORS = 'checked_out_with_errors',
}

export interface Barcode {
  format: string;
  data: string;
  image: string;
}

export interface Ticket {
  delivery_methods: string[];
  barcodes: Barcode[];
}

export interface Reservation {
  product_selection: string;
  price: ApiPrice;
  commission: ApiPrice;
  concession_id: string | null;
  concession_name: string | null;
  seat_selection: string | null;
  ticket: Ticket | null;
  voucher_url: string | null;
  supplier_reference: string | null;
}

export interface BasketItemAdjustment {
  name: string;
  type: string;
  price: ApiPrice;
}

export interface BasketItem {
  id: string;
  created_on: string;
  external_reference: string | null;
  event_id: string;
  event_name: string;
  event?: { admission_type: AdmissionType };
  availability_id: string;
  adjustments: BasketItemAdjustment[];
  product_id: string;
  band_id: string | null;
  cancellation_allowed: boolean;
  cancellation_policy: string;
  important_information: string[];
  ticket_instructions: string[];
  from_date: ApiDate;
  to_date: ApiDate;
  reservations: Reservation[];
  voucher_url: string | null;
  price: ApiPrice;
  booking_fee: ApiPrice;
  supplier_adjustment: ApiPrice;
  location: VenueLocation | null;
  event_image: Image | null;
  booked_successfully: boolean;
  status: BasketItemStatus;
  reservation_attempts: number;
  ttl: string;
  display_data: NiceTicketData;
}

export interface CareemUser {
  _id: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  phone_number: string;
  phone_number_verified: string;
  zoneinfo: string;
}

export interface ApiOrder {
  order_tense: 'past' | 'future';
  orders: OrderItem[];
  total_count: number;
  user: CareemUser;
}

interface OrderItemDisplayData {
  reservations: {
    concessions: string;
    reservations_combined: boolean;
    seats: string;
  };
}

export interface OrderItem {
  id: string;
  basket_id: string;
  cancellation_policy: string;
  event_image: Image | null;
  event_name: string;
  from_date: ApiDate;
  to_date: ApiDate;
  location: VenueLocation | null;
  price: ApiPrice;
  booking_fee: ApiPrice;
  supplier_adjustment: ApiPrice;
  basket_total_discount: ApiPrice;
  basket_total_price: ApiPrice;
  reservations: Reservation[];
  voucher_url: string;
  status: BasketItemStatus;
  display_data: OrderItemDisplayData;
}

export interface SessionObject {
  session_id: string;
  view_url: string;
}

export interface EventSearchParams {
  category?: string[];
  city?: string[];
  country_code?: string[];
  t1?: string;
  t2?: string;
  text?: string;
  profile?: string;
  promocode?: string;
  sort_by?: string; // "price_asc" | "price_desc";
  newTab?: string;
  // NOT actual parameters we search by
  currency?: string;
  session?: string;
  event_name?: string;
  event_id?: string;
  hideExtraPrice?: string;
}

export interface Customer {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  birthdate?: string;
  house_number?: string;
  street_name?: string;
  postal_code?: string;
  city?: string;
  country_code?: string;
}

export interface StripeObject {
  stripe_payment_intent: string;
  stripe_payment_intent_secret: string;
  customer: Customer;
}

interface ThemeIcons {
  [key: string]: string;
}
interface ThemeImages {
  landing_photo?: ApiImage;
}

export interface ThemeFont {
  family: string;
  /**
   * Array of src URLs for this font
   */
  urls: ThemeFontURL[];
  weight?: string;
}

export interface ThemeFontURL {
  format: string;
  url: string;
}

export interface ThemeMenuButton {
  name: string;
  url: string;
  icon: string;
}

export interface Theme {
  icons: ThemeIcons;
  variables: Record<string, string>;
  images: ThemeImages;
  fonts: ThemeFont[];
  menu_buttons: ThemeMenuButton[];
  title?: string;
  favicon?: string;
}

export interface CustomisationLocation {
  country_codes?: string[];
  cities?: string[];
  count: number;
}

export interface CustomScript {
  src: string;
}

enum CustomisationDateRangeUnit {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEARS = 'years',
}
export type SearchSort = 'popularity' | 'price_asc' | 'price_desc';

export interface CustomisationDateRange {
  unit: CustomisationDateRangeUnit;
  value: number;
}

export interface HomepageOrder {
  locations: number;
  events: number;
  categories: number;
}

export interface MarketingBanner {
  en: { text: string; keyword: string };
  ar: { text: string; keyword: string };
  enabled: boolean;
}

export interface CustomCarousel {
  title: {
    en: string;
    ar: string;
  };
  event_ids: string[];
  enabled: boolean;
  categories: string[];
}

export interface DiscountedEvent {
  event_ids: string[];
  banner_text: {
    en: string;
    ar: string;
  };
  promocode_text: {
    en: string;
    ar: string;
  };
  enabled: boolean;
}

export interface ProfileCustomisations {
  popular_events?: string[];
  popular_locations?: CustomisationLocation;
  popular_categories?: string[];
  custom_scripts?: CustomScript[];
  date_range?: CustomisationDateRange;
  zendesk_script?: string;
  homepage_order?: HomepageOrder;
  marketing_banner?: MarketingBanner;
  custom_carousels?: CustomCarousel[];
  discounted_events?: DiscountedEvent[];
  logo_redirect_url?: string;
}

export interface Profile {
  name: string;
  theme: Theme;
  user: string;
  default_language: string;
  default_currency: string;
  customisations?: ProfileCustomisations;
}

export enum RenderTypes {
  // CANVAS = "canvas",
  SVG_BASE_LAYER = 'svg-base-layer',
  SVG_RENDER = 'svg-render',
  IMG_OVERLAY = 'img-overlay',
  // EXTERNAL_WIDGET = 'external-widget',
}

interface SeatingPlanConfiguration {
  event_key: string;
  public_workspace_key: string;
  supplier_name: string;
  type: string;
  package_seat_categories: { category: string[] };
}

interface SeatingPlanData {
  id: string;
  name: string;
  render_type: RenderTypes | string;
  currency: string;
  supplier_name?: string;
  rules: SeatPlanValidationRules;
  configuration: SeatingPlanConfiguration;
  base_layer?: string | null;
  view_id?: null;
}

export interface SeatingBlock {
  id: string;
  name: string;
  base_layer: string | null;
  view_id: string | null;
  from_price: number;
  to_price: number | null;
  seats_available: boolean;
  best_allocation: boolean;
  x: number;
  y: number;
  polygon: Array<[number, number]> | null;
  blocks: SeatingBlock[];
}

export interface SeatingPlanBlocksData extends SeatingPlanData {
  blocks: SeatingBlock[];
}

export interface Seat {
  id: string;
  view_id: string | null;
  name?: string;
  price: number | null;
  price_id?: string;
  free: boolean;
  column?: string;
  row: string;
  width: number;
  height: number;
  x: number;
  y: number;
  grid_x: number;
  grid_y: number;
  visibility_info: string | null;
  accessibility_info?: string | null;
  block_id: string;
  seat?: string;
  group?: { grid_x: number; grid_y: number }[];
}

export interface SeatingPlanSeatsData extends SeatingPlanData {
  seats: Array<Seat>;
}

export interface SeatsDictionary {
  [seatId: string]: Seat;
}

export interface Image {
  desktop: string | null;
  mobile?: string | null;
  thumbnail?: string | null;
}

export interface City {
  /** City name (in English) e.g. London */
  name: string;
  longitude: number;
  latitude: number;
  images: readonly Image[];
  group_name?: string;
}

export interface Location {
  /** Unique two-letter ISO alpha-2 country code e.g. GB, DE, US */
  country_code: string;
  /** Country name (in English) e.g. United Kingdom */
  country_name: string;
  /** Alternate names and other country-codes e.g. UK, GBR, Great Britain */
  alternate_names: ReadonlyArray<string>;
  images: readonly Image[];
  cities: readonly City[];
  event_count: number;
}

export interface Category {
  /** Unique category name. */
  id: string;
  name: string;
  localised_name: string;
  images: readonly Image[];
  parent_name?: string | null;
  count?: number;
  children?: string[];
  event_count: number;
}

export interface Metadata {
  locations: readonly Location[];
  categories: readonly Category[];
}

export type OptionType = 'choice' | 'number' | 'datetime' | 'location' | 'text';

export interface BaseOption<T extends OptionType> {
  id: string;
  name: string;
  description: string;
  required: boolean;
  minimum_ticket_quantity?: number | null;
  maximum_ticket_quantity?: number;

  type: T;
}

export interface ChoiceOptionValue {
  name: string;
  description: string;
  price: ApiPrice;
  value: string;

  minimum_total_quantity?: number;
  maximum_total_quantity?: number;

  minimum_value_quantity?: number;
  maximum_value_quantity?: number;
}

export interface ChoiceOption extends BaseOption<'choice'> {
  minimum_selection_count?: number;
  maximum_selection_count: number;

  minimum_option_quantity?: number;
  maximum_option_quantity?: number;

  minimum_option_quantity_per_reservation?: number;
  maximum_option_quantity_per_reservation: number;

  values: ChoiceOptionValue[];
}

export interface NumberOption extends BaseOption<'number'> {
  minimum?: number;
  maximum?: number;
  /** Increment/decrement step delta value. Always greater than zero. The value must be divisible by this value. */
  step: number;
}

export type DatetimeControlType = 'date' | 'time' | 'datetime';
export interface DatetimeOption extends BaseOption<'number'> {
  /** Earliest valid datetime. */
  minimum?: string;
  /** Latest valid datetime. */
  maximum?: string;
  /** Increment/decrement step delta value in seconds. Always greater than zero. The value must be divisible by this value. */
  step: number;
  /**
   * Recommended representation of this option.
   * This field is auto-populated based on `minimum`, `maximum` and `step`.
   * If minimum and maximum are on the same day and step is less than a day, this will be `time`.
   * If minimum and maximum are on different days and step is 86400, this will be `date`.
   * Anything else will be `datetime`.
   * Note that it could also be represented as a date control if `step` is not 86400, but is nonetheless such that there is only one time on each possible day.
   * However, this isn't generally likely to occur. It is more likely that such a case would be presented as a `choice` option with discrete datetime values.
   */
  control_type: DatetimeControlType;
}

export interface LocationOption extends BaseOption<'location'> {}

export interface TextOption extends BaseOption<'text'> {
  multiline: boolean;
  minimum_length: number;
  maximum_length?: number;
}

export type Option =
  | ChoiceOption
  | NumberOption
  | DatetimeOption
  | LocationOption
  | TextOption;

export interface ConcessionApi {
  id: string;
  name: string;
  description: string;
  price: ApiPrice;
  commission?: ApiPrice;
  accompaniments?: string[];

  available_capacity?: number | null;
  concession_options: Option[];
  minimum_quantity?: number | null;
  maximum_quantity?: number | null;
}

export interface ProductOptionsValue extends Omit<TextOption, 'id'> {
  price: ApiPrice;
  value: unknown;
  parent_id: string;
}

export interface ProductOptionsApi {
  id: string;
  description: string;
  name: string;
  control_type: 'radio' | string;
  type: 'choice' | 'text' | 'number' | 'datetime';
  minimum_option_quantity?: number | null;
  maximum_option_quantity?: number | null;
  maximum_option_quantity_per_reservation: number | null;
  values: ProductOptionsValue[];
  required: boolean;
  format: string | null;
}

export interface AvailProduct extends ApiPrice {
  availability_id: string;
  id: string;
  name: string;
  price: ApiPrice;

  product_options: ProductOptionsApi[];
  concessions: ConcessionApi[];
  available_capacity?: number | null;
  maximum_tickets?: number | null;
  sequential?: boolean;
}

export type PersonalizationFields =
  | 'first_name'
  | 'last_name'
  | 'phone_number'
  | 'email'
  | 'birthdate'
  | 'city'
  | 'house_number'
  | 'street_name'
  | 'postal_code'
  | 'country_code';

export type PersonalDetails = { [key in PersonalizationFields]?: string };

export interface AvailabilitySlot {
  ttl: string;
  id: string;
  latest: boolean;
  name?: string | null;
  event_id: string;
  from_date: ApiDate;
  to_date: ApiDate;
  venue_location?: VenueLocation | null;
  from_price: ApiPrice;
  available_capacity?: number | null;
  seatplan_required: boolean;
  seatplan_source?: string | null;
  personalisations_required: {
    fields: {
      [key in PersonalizationFields]: { regex: string };
    };
  };
  personalisations_occurrence: 'once' | 'per_ticket';
  products: AvailProduct[];
  maximum_tickets: number;
  minimum_tickets: number;
}

export interface TicketValidationRules {
  mix_price_bands?: boolean;
  min_tickets: number;
  max_tickets?: number | null;
}

export interface AvailabilitySession {
  // User context
  _id: string;
  event_id: string;
  basket_id: string;
  distributor_id: string;

  // Event context
  admission_type: AdmissionType;
  booking_type: BookingType;
  from_price: ApiPrice;
  soft_availability_t1: string;
  soft_availability_t2: string;

  // Session data
  latest: boolean;
  rules: TicketValidationRules;
  ttl: string;
  availability: AvailabilitySlot[];
}

export interface AvailabilityFlow {
  minimum_steps: number;
  maximum_steps: number;
  can_go_back: boolean;
  final: boolean;

  previous_steps?: AvailabilityFlowStep[];
  current_steps: AvailabilityFlowStep[];
  state: BasketItemCreate;
}

export interface BasketItemCreate {
  availability_id: string;
  product_id: string;
  band_id?: string;
  booking_options: BookingOption[];
  product_options: OptionChoice[];
  display_data: NiceTicketData;
}

export interface BookingOption {
  concession_id?: string;
  concession_options?: OptionChoice[];
  quantity?: number;
  seat_ids?: string[];
  customers?: Customer[];
}

export interface AvailabilityFlowStep {
  products: AvailabilityFlowProduct[];
  product_options: ProductOptionsApi[];
  concession_options: ConcessionOption[];
}

export interface FlowProductOptionsApi extends ProductOptionsApi {}

export interface ConcessionOption {
  concession_ids: string[];
  concession_options: ProductOptionsApi[];
}

export interface AvailabilityFlowProduct {
  id: string;
  name: string;
  description?: string;
  price: ApiPrice;
  commission?: ApiPrice;
  concessions?: ConcessionApi[];
  available_capacity: number | null;
  minimum_tickets?: number;
  maximum_tickets?: number | null;
  availability_id: string;
  product_options?: ProductOptionsApi[];
  product_bands?: unknown;
  sequential: boolean;
}

export interface DayAvailability {
  availability: AvailabilitySlot[];
}

export interface OptionChoice {
  option_id: string;
  choice_value: any;
  quantity?: number;
  identifier?: string | undefined;
}

export interface BasketAddOption {
  concession_id?: string | null;
  quantity?: number;
  concession_options?: Array<OptionChoice>;
  seat_ids?: Array<string>; // eg ["BALCONY_(RESTRICTED_VIEW)-C6"]
  customers?: Array<PersonalDetails>;
}

export interface BasketAddData {
  availability_id?: string; // the avail slot id
  product_id?: string;
  booking_options?: Array<BasketAddOption>;
  product_options?: Array<OptionChoice>;
  display_data: NiceTicketData;
}
