import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { QueryObserverResult, useQuery, useQueryClient } from 'react-query';
import { AppViewContext } from '../../contextProviders/AppViewProvider';
import { CareemContext } from '../../contextProviders/CareemProvider';
import { ErrorReportingContext } from '../../contextProviders/ErrorReportingProvider';
import {
  Basket,
  BasketItem,
  BasketItemStatus,
  BasketStatus,
  City,
  Event,
  EventSearchParams,
  Metadata,
} from '../../definitions/tickitto';

import { SettingsContext } from '../../contextProviders/SettingsProvider';
import tktApi from '../instances/tickitto';
import availabilityClient from './availability';
import basketClient from './basket';
import seatingClient from './seating';
import themesClient from './themes';

export const apiPrefix = `${process.env.REACT_APP_TIK_API_ENDPOINT}api`;

export function getTickittoApiHeaders(): Record<string, string> {
  const key = process.env.REACT_APP_TIK_API_KEY;
  return key ? { key } : {};
}

export enum SESSION_STORAGE_KEYS {
  BASKET_ID = 'basketId',
  LAST_SCROLL_POSTION = 'lastScrollPosition',
  LAST_NO_EVENTS_SEEN = 'lastNoEventsSeen',
}

// remove keys from object that have null or empty string values
export const cleanSearchObj = (
  params: Record<string, any>
): URLSearchParams => {
  const urlParams = new URLSearchParams();

  Object.keys(params).forEach((key: string) => {
    if (Array.isArray(params[key])) {
      if (params[key].length > 0) {
        params[key].forEach((value: string) => {
          urlParams.append(key, value);
        });
      }
    } else if (params[key] != null && params[key] !== '') {
      if (key === 't1' || key === 't2') {
        if (key === 't1' && params[key] === 'now') {
          urlParams.append(
            key,
            moment().locale('en').utc().format('YYYY-MM-DD')
          );
        } else if (key === 't2' && params[key] === 'flexible') {
          urlParams.append(
            key,
            moment().locale('en').add(1, 'year').utc().format('YYYY-MM-DD')
          );
        } else {
          urlParams.append(
            key,
            moment(params[key]).locale('en').utc().format('YYYY-MM-DD')
          );
        }
      } else if (key === 'profile') {
        urlParams.append('profile_name', params[key]);
      } else {
        urlParams.append(key, params[key]);
      }
    }
  });

  if (!urlParams.get('limit')) {
    urlParams.append('limit', '50');
  }

  return urlParams;
};

export interface EventQuery extends EventSearchParams {
  limit?: string;
  skip?: string;
}

// interface EventSearch extends EventSearchParams, PaginationParams {}
// gets events from our own tickitto catalogue
export function useGetEventsData(searchParams: EventQuery) {
  // clean undefined values
  const { active: isCareem } = useContext(CareemContext);
  // eslint-disable-next-line no-param-reassign
  if (isCareem) searchParams.profile = 'careem_tikety';
  const cleanSearchParams = cleanSearchObj(searchParams);
  const { locale } = useContext(SettingsContext);

  return useQuery<{ events: Array<Event>; count: number }>(
    ['events/', 'GET', cleanSearchParams.toString(), locale],
    async () => {
      const { data, headers } = await tktApi.get('events/', {
        params: cleanSearchParams,
        headers: {
          'accept-language': locale || 'en',
        },
      });

      return { events: data, count: headers['x-total-count'] };
    },
    {
      staleTime: 1000 * 60 * 60, // 60min
      placeholderData: { events: [], count: 0 },
    }
  );
}

// gets events, categories and locations with count from tickitto catalogue
export function useSearch(searchParams: EventQuery) {
  const { active: isCareem } = useContext(CareemContext);
  // eslint-disable-next-line no-param-reassign
  if (isCareem) searchParams.profile = 'careem_tikety';
  const cleanSearchParams = cleanSearchObj(searchParams);
  const { locale } = useContext(SettingsContext);

  return useQuery<{
    events: Array<Event>;
    eventCount: number;
    categoryCount: { [categoryName: string]: number };
    cityCount: { [cityName: string]: number };
    countryCount: { [countryName: string]: number };
  }>(
    ['search/', 'GET', cleanSearchParams.toString(), locale],
    async () => {
      const { data } = await tktApi.get('search/', {
        params: cleanSearchParams,
        headers: {
          'accept-language': locale || 'en',
        },
      });

      return {
        events: data.results,
        eventCount: data.total_count,
        categoryCount: data.category_count,
        cityCount: data.city_count,
        countryCount: data.country_count,
      };
    },
    {
      staleTime: 1000 * 60 * 60, // 60min
      placeholderData: {
        events: [],
        eventCount: 0,
        categoryCount: {},
        cityCount: {},
        countryCount: {},
      },
    }
  );
}

export function useGetEventById(eventId: string) {
  const queryClient: any = useQueryClient();
  const { locale } = useContext(SettingsContext);

  return useQuery<Event>(
    ['events/', 'GET', eventId, locale],
    async () => {
      const { data } = await tktApi.get(`events/${eventId}`, {
        headers: {
          'accept-language': locale || 'en',
        },
      });

      return data;
    },
    {
      initialData: () => {
        // this basically checks the cached events data to see if we already fetched this event before
        const cachedEventsData = queryClient.getQueryData({
          queryKey: ['events/', 'GET', eventId, locale],
          exact: false,
        });

        if (cachedEventsData != null) {
          for (let i = 0; i < cachedEventsData.length; i++) {
            if (cachedEventsData[i].event_id === eventId) {
              return cachedEventsData[i];
            }
          }
        }

        return undefined;
      },
      staleTime: 1000 * 60 * 60, // 60min
    }
  );
}

// gets all the metadata we have from the metadata endpoint
export function useMetadata(): QueryObserverResult<Metadata> {
  const { locale } = useContext(SettingsContext);

  return useQuery(
    ['/metadata', locale],
    async () => {
      const { data } = await tktApi.get('/metadata/', {
        headers: {
          'accept-language': locale || 'en',
        },
      });
      return data;
    },
    {
      placeholderData: { categories: [], locations: [] },
      staleTime: 1000 * 60 * 5, // 5min,
    }
  );
}

export function useGetLocationImage(
  cityName: string,
  countryCode?: string
): string | undefined {
  const { isFetched, data: metadata } = useMetadata();
  const { isTablet } = useContext(AppViewContext);
  const { throwError } = useContext(ErrorReportingContext);
  if (isFetched && metadata != null) {
    const { locations } = metadata;
    let citiesList: Array<City> = [];

    // build cities list
    if (countryCode) {
      const matchingCountry = locations.find(
        (loc) =>
          loc.country_code.toUpperCase().trim() ===
          countryCode.toUpperCase().trim()
      );

      if (matchingCountry != null) {
        citiesList = matchingCountry.cities as Array<City>;
      }
    } else {
      // flatten locations to a list of cities
      locations.forEach((loc) => {
        citiesList = [...citiesList, ...loc.cities];
      });
    }

    // find matchingCity
    const matchingCity = citiesList.find(
      (city) => city.name.toLowerCase().trim() === cityName.toLowerCase().trim()
    );

    // get appropriate image
    if (
      matchingCity != null &&
      Array.isArray(matchingCity.images) &&
      matchingCity.images.length
    ) {
      if (isTablet && matchingCity.images[0].mobile != null) {
        return (matchingCity.images[0].mobile as string)?.replace(
          'storage.googleapis.com/tickitto_assets',
          process.env.REACT_APP_IMAGE_ENGINE_URL as string
        );
      }

      if (matchingCity.images[0].desktop != null) {
        return (matchingCity.images[0]?.desktop as string)?.replace(
          'storage.googleapis.com/tickitto_assets',
          process.env.REACT_APP_IMAGE_ENGINE_URL as string
        );
      }
      throwError(
        `A desktop image is missing for ${cityName} - ${countryCode}, the desktop image is required`
      );
    }
  }

  return undefined;
}

export function useGetCategoryImage(categoryName: string): string | undefined {
  const { isFetched, data: metadata } = useMetadata();
  const { isTablet } = useContext(AppViewContext);
  const { throwError } = useContext(ErrorReportingContext);
  if (isFetched && metadata != null) {
    const { categories } = metadata;

    // build cities list
    if (categoryName) {
      const matchingCategory = categories.find(
        (cat) =>
          cat.name.toUpperCase().trim() === categoryName.toUpperCase().trim()
      );

      if (matchingCategory != null) {
        if (isTablet && matchingCategory.images?.[0]?.mobile != null) {
          return matchingCategory.images[0].mobile?.replace(
            'storage.googleapis.com/tickitto_assets',
            process.env.REACT_APP_IMAGE_ENGINE_URL as string
          );
        }

        if (matchingCategory.images?.[0]?.desktop != null) {
          return matchingCategory.images[0]?.desktop?.replace(
            'storage.googleapis.com/tickitto_assets',
            process.env.REACT_APP_IMAGE_ENGINE_URL as string
          );
        }

        throwError(
          `A desktop image is missing for ${categoryName}, the desktop image is required`
        );
      }
    }
  }
  return undefined;
}

function useBasketItemsCheckoutPolling(
  basketId: string = localStorage.getItem(SESSION_STORAGE_KEYS.BASKET_ID) || '',
  extraParams: {
    disable: boolean;
    pollInterval: number;
  } = { disable: false, pollInterval: 1000 }
) {
  const [isPollingActive, togglePolling] = useState(!extraParams.disable);
  const lastNonNullData = useRef<Basket>();

  useEffect(() => {
    togglePolling(!extraParams.disable);
  }, [extraParams.disable]);

  const res = useQuery<Basket>(
    ['basket/', 'GET', { basket_id: basketId }],
    async () => {
      const { data } = await tktApi.get('basket/', {
        params: { basket_id: basketId },
      });

      return data;
    },
    {
      cacheTime: 0,
      refetchInterval: extraParams.pollInterval,
      enabled: isPollingActive,
      refetchOnWindowFocus: false,
    }
  );

  let basketReservationsComplete = lastNonNullData.current != null;
  let noBasketItemsBookedSuccessfully = false;
  let someBasketItemsBookingFailed = false;

  if (res.status === 'error') {
    if (isPollingActive) {
      togglePolling(false);
    }
    return {
      data: lastNonNullData.current,
      isFetched: true,
      basketIdError: true,
      error: 'An error occurred while fetching basket items',
    };
  }

  if (res.data != null && Array.isArray(res.data.items)) {
    lastNonNullData.current = res.data;
    const basketItems = res.data.items;

    basketReservationsComplete =
      res.data.checkout_status === BasketStatus.PAYMENT_FAILED ||
      basketItems.every(
        (item: BasketItem) =>
          item.status === BasketItemStatus.BOOKED ||
          item.status === BasketItemStatus.ERRORED ||
          item.status === BasketItemStatus.FULFILMENT_ERRORED ||
          item.status === BasketItemStatus.FULFILMENT_PENDING
      );

    if (basketReservationsComplete) {
      if (isPollingActive) {
        togglePolling(false);
      }
      noBasketItemsBookedSuccessfully =
        res.data.checkout_status === BasketStatus.PAYMENT_FAILED ||
        basketItems.every(
          (item: BasketItem) => item.status === BasketItemStatus.ERRORED
        );

      someBasketItemsBookingFailed = basketItems.some(
        (item: BasketItem) => item.status === BasketItemStatus.ERRORED
      );
    }
  }

  if (res.data?.checkout_status === BasketStatus.CHECKED_OUT_WITH_ERRORS) {
    return {
      data: lastNonNullData.current,
      isFetched: true,
      someFailed: true,
      error: 'We were unable to book some of the requested basket items',
    };
  }

  if (basketReservationsComplete) {
    if (noBasketItemsBookedSuccessfully) {
      return {
        data: lastNonNullData.current,
        isFetched: true,
        allFailed: true,
        error: 'We were unable to book any of the requested basket items',
      };
    }
    if (someBasketItemsBookingFailed) {
      return {
        data: lastNonNullData.current,
        isFetched: true,
        someFailed: true,
        error: 'We were unable to book some of the requested basket items',
      };
    }

    return { data: lastNonNullData.current, isFetched: true };
  }
  return { data: lastNonNullData.current, isFetched: false };
}

const client = {
  useMetadata,
  useGetLocationImage,
  useGetCategoryImage,
  useGetEventsData,
  useSearch,
  useGetEventById,
  useBasketItemsCheckoutPolling,
  ...availabilityClient,
  ...themesClient,
  ...basketClient,
  ...seatingClient,
};

export default client;
