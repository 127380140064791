import React, { useState, createContext } from 'react';

export interface WidgetErrorUIControllerContext {
  errorUI: JSX.Element | null;
  setErrorUi: React.Dispatch<JSX.Element | null>;
}

const initialState: WidgetErrorUIControllerContext = {
  errorUI: null,
  setErrorUi: () => (
    <div>
      An unknown error has occurred, but don&apos;t worry, we&apos;re already
      aware of the problem and are looking into it.
    </div>
  ),
};

// this context is simply a utility to communicate with the widget deep in the component tree. Its a way to get event out of it or pass it some state it should use
export const WidgetErrorUIControllerContext =
  createContext<WidgetErrorUIControllerContext>(initialState);

export function WidgetErrorUIControllerProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [errorUI, setErrorUi] = useState<JSX.Element | null>(null);

  return (
    <WidgetErrorUIControllerContext.Provider value={{ errorUI, setErrorUi }}>
      {children}
    </WidgetErrorUIControllerContext.Provider>
  );
}
