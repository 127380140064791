import React from 'react';
import './style.scss';
import classNames from 'classnames';

export interface RowProps {
  justify?: 'center' | 'space-between' | 'end' | 'start';
  align?: 'center' | 'start' | 'baseline';
  gutterSize?: 'm' | 's' | 'l';
  className?: string;
  noWrap?: boolean;
  fill?: boolean;
  testId?: string;
  onClick?: () => void;
}

const Row: React.FC<RowProps> = ({
  children,
  justify = 'start',
  align = 'center',
  fill = false,
  className,
  gutterSize,
  noWrap,
  testId,
  onClick = () => {},
}) => {
  const mainClass = classNames('row', 'section-block', className, {
    'row--center': justify === 'center',
    'row--space-between': justify === 'space-between',
    'row--justify-end': justify === 'end',
    'row--no-wrap': noWrap,
    'row--fill': fill,
    [`row--gutter-${gutterSize}`]: gutterSize != null,
    [`row--align-${align}`]: align,
  });
  return (
    <div
      className={mainClass}
      data-testid={testId}
      onClick={onClick}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default Row;
