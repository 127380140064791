/*
  here we define all of the possible variables that can be used
  These vars all get also applied to antd so make sure that the name that these use matches the one in antd
  In scss you use them like var(--primary-color) as on load the appropriate value gets assigned to the css variable.

  When adding a new variable this is what needs to be done:
    1. add the variable in default.js
*/

import { IconsDataType } from '../../components/Icon/data';
import { ThemeFont } from '../../definitions/tickitto';

interface ThemeObject {
  [name: string]: string;
}

export const evalThemeMap = (
  defaultTheme: Map<string, string>,
  newTheme: ThemeObject = {}
): ThemeObject => {
  // merge the default map with the map object we reiceved
  const mergedThemes = defaultTheme;
  Object.keys(newTheme).forEach((key) => {
    mergedThemes.set(key, newTheme[key]);
  });

  const evaluatedObject: ThemeObject = {};
  mergedThemes.forEach((val, key) => {
    // check if we need to perform a replace
    if (val.includes('@')) {
      try {
        const varNamePattern = /@(.*?)(?=\s|$)/g;
        const varName = val.match(varNamePattern)![0].replace('@', ''); // remove the starting @ sign
        const cleanVarName = varName.replace(/_/g, '-'); // replace underscores with normal dashes so we get the correct value from evaluatedObject

        evaluatedObject[key] =
          val.replace(`@${varName}`, '') + evaluatedObject[cleanVarName]!;
      } catch (err) {
        console.error('Error:', key, val);
      }
    } else {
      evaluatedObject[key] = val;
    }
  });
  return evaluatedObject;
};

export const applyTheme = (theme: ThemeObject) => {
  // updates the css variabless

  Object.keys(theme).forEach((key) => {
    const root = document.documentElement;
    root.style.setProperty(`--${key}`, theme[key]);
  });
};

export const evalThemeIcons = (
  defaultTheme: IconsDataType,
  apiIcons: ThemeObject = {}
): IconsDataType => {
  const newIconsThemeObj: IconsDataType = { ...defaultTheme };

  Object.keys(apiIcons).forEach((key) => {
    if (Object.keys(newIconsThemeObj).includes(key)) {
      (newIconsThemeObj as any)[key] = {
        ...(newIconsThemeObj as any)[key],
        src: apiIcons[key],
      };
    }
  });

  return newIconsThemeObj;
};

export const verifyIncomingTheme = (
  defaultTheme: Map<string, string>,
  apiTheme: ThemeObject
): string | null => {
  const defaultThemeKeys: Array<string> = Array.from(defaultTheme.keys());
  const apiKeys = Object.keys(apiTheme);

  const missingKeys = [];
  for (let i = 0; i < defaultThemeKeys.length; i++) {
    if (!apiKeys.includes(defaultThemeKeys[i])) {
      missingKeys.push(defaultThemeKeys[i]);
    }
  }

  if (missingKeys.length > 0) {
    return `The api theme did not send these constants ${missingKeys} which the Front end expects. 
    They should be part of the default theme with these values: ${missingKeys.map(
      (key) => `${key}=${defaultTheme.get(key)}`
    )}`;
  }

  return null;
};

export const applyThemeFonts = (fonts: Array<ThemeFont> = []): void => {
  fonts.forEach((font) => {
    font.urls.forEach((url) => {
      const linkElem = document.createElement('link');
      linkElem.href = url.url;
      linkElem.rel = 'stylesheet';
      document.head.appendChild(linkElem);
    });
  });
};
